.item-background {
  background-color: #ccc;
  box-shadow: 3px 3px 5px #aaa;
  border-radius: 3px;

  &.selected {
    background-color: #888;
    box-shadow: 3px 3px 5px #666;
  }
}

.item-slot {
  position: relative;
  user-select: none;

  .item-slot-bg-text {
    position: absolute;
    user-select: none;
    color: #888;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: 100px;

    &.small {
      font-size: 75%;
    }

    &.xsmall {
      font-size: 50%;
    }
  }
}

.item-slot {
  width: 64px;
  height: 64px;
}

.small {
  .item-slot {
    width: 48px;
    height: 48px;
  }
}

.xsmall {
  .item-slot {
    width: 32px;
    height: 32px;
  }
}

.drag-over-border {
  border: none !important;
  outline: 3px dashed green !important;
  outline-offset: -2px;
}

.drag-hint-border {
  border: none !important;
  outline: 3px dashed blue;
  background-color: rgba(0, 0, 255, 0.2);
  outline-offset: -2px;
}

.drag-border {
  border: none !important;
  outline: 3px dashed red;
}
