
.window-container {
  position: absolute;
}

.window {
  background-color: $backgroundColor;
  outline: 1px solid #000;

  display: flex;
  flex-direction: column;

  &.active .window-header {
    background-color: $titleColor;
  }

  .window-header {
    background-color: lighten($titleColor, 20%);
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 2px;

    overflow: hidden;
    padding-right: 0;
    border-bottom: 1px solid $titleColor;
    user-select: none;
  }

  .window-body {
    flex: 1;
    overflow: hidden;
  }
}

.window-has-more-buttons {
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  .window-extra-buttons {
    margin-right: 40px;
    display: inline-flex;

    button {
      pointer-events: all;
      max-height: 32px;
      max-width: 32px;
      height: 32px;
      width: 32px;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      &.is-selector:not(.mat-button-disabled) {
        opacity: 0.5;
      }
    }
  }
}
