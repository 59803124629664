// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
@import '@angular/cdk/overlay-prebuilt.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$landoftherair-primary: mat-palette($mat-orange);
$landoftherair-accent: mat-palette($mat-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$landoftherair-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$landoftherair-theme: mat-dark-theme(
  $landoftherair-primary,
  $landoftherair-accent,
  $landoftherair-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($landoftherair-theme);

/* You can add global styles to this file, and also import other style files */

.cdk-overlay-container {
  z-index: 2500;
}

.split-button {
  display: flex;

  mat-form-field {
    flex: 1;
  }
}

.mat-tab-label {
  min-width: 90px !important;
  padding: 0 12px !important;
  height: 36px !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-toolbar.mat-warn {
  background: #7c0d0e !important;
}

@import 'theme';

@import 'macicons/macicons';
@import 'global';
@import 'helpers';
@import 'window';
@import 'game';
