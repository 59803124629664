


$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  .m-#{$space} {
    @each $side in $sides {
      margin-#{$side}: #{$space}px !important;
    }
  }

  .p-#{$space} {
    @each $side in $sides {
      padding-#{$side}: #{$space}px !important;
    }
  }

  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.bordered {
  border-width: 1px;
  border-style: solid;
}

.true-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.thin-button {
  line-height: 18px !important;
  max-height: 18px;

  mat-icon {
    font-size: 16px;
    line-height: 20px !important;
  }
}

.hidden {
  display: none !important;
}

.cursor-draggable {
  cursor: grab;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.background-text {
  color: rgba(200, 200, 200, 0.7);
}

.big-text {
  font-size: 2em;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bar-container {
  text-align: center;
  position: relative;
  display: flex;

  background-color: silver;

  .bar, .bar-text {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .bar {
    z-index: 5;
  }

  .bar-text {
    z-index: 6;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    color: white;
    text-shadow:
     -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
  }
}

.higher-stat {
  color: #60e660;
}

.lower-stat {
  color: #ff4e4e;
}

.error-container {
  position: relative;

  mat-error {
    position: absolute;
    top: -20px;
  }
}
