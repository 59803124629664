$macicons-3d-stairs: "\ea01";
$macicons-abdominal-armor: "\ea02";
$macicons-abstract-005: "\ea03";
$macicons-abstract-007: "\ea04";
$macicons-abstract-010: "\ea05";
$macicons-abstract-011: "\ea06";
$macicons-abstract-024: "\ea07";
$macicons-abstract-027: "\ea08";
$macicons-abstract-033: "\ea09";
$macicons-abstract-034: "\ea0a";
$macicons-abstract-038: "\ea0b";
$macicons-abstract-041: "\ea0c";
$macicons-abstract-047: "\ea0d";
$macicons-abstract-053: "\ea0e";
$macicons-abstract-066: "\ea0f";
$macicons-abstract-071: "\ea10";
$macicons-abstract-088: "\ea11";
$macicons-abstract-097: "\ea12";
$macicons-achilles-heel: "\ea13";
$macicons-acorn: "\ea14";
$macicons-ages: "\ea15";
$macicons-air-zigzag: "\ea16";
$macicons-alien-fire: "\ea17";
$macicons-all-for-one: "\ea18";
$macicons-all-seeing-eye: "\ea19";
$macicons-alligator-clip: "\ea1a";
$macicons-amplitude: "\ea1b";
$macicons-anarchy: "\ea1c";
$macicons-ancient-sword: "\ea1d";
$macicons-angel-outfit: "\ea1e";
$macicons-angry-eyes: "\ea1f";
$macicons-ankh: "\ea20";
$macicons-anvil-impact: "\ea21";
$macicons-anvil: "\ea22";
$macicons-apothecary: "\ea23";
$macicons-archer: "\ea24";
$macicons-archive-register: "\ea25";
$macicons-archive-research: "\ea26";
$macicons-arcing-bolt: "\ea27";
$macicons-armor-downgrade: "\ea28";
$macicons-armor-punch: "\ea29";
$macicons-armor-upgrade: "\ea2a";
$macicons-armor-vest: "\ea2b";
$macicons-arrow-cluster: "\ea2c";
$macicons-arrow-flights: "\ea2d";
$macicons-arrow-scope: "\ea2e";
$macicons-arrowed: "\ea2f";
$macicons-arrowhead: "\ea30";
$macicons-arrows-shield: "\ea31";
$macicons-artichoke: "\ea32";
$macicons-artificial-hive: "\ea33";
$macicons-artillery-shell: "\ea34";
$macicons-assassin-pocket: "\ea35";
$macicons-astrolabe: "\ea36";
$macicons-astronaut-helmet: "\ea37";
$macicons-at-sea: "\ea38";
$macicons-atlas: "\ea39";
$macicons-atom-core: "\ea3a";
$macicons-atomic-slashes: "\ea3b";
$macicons-attached-shield: "\ea3c";
$macicons-aura: "\ea3d";
$macicons-auto-repair: "\ea3e";
$macicons-autogun: "\ea3f";
$macicons-awareness: "\ea40";
$macicons-axe-in-stump: "\ea41";
$macicons-axe-swing: "\ea42";
$macicons-axe-sword: "\ea43";
$macicons-aztec-calendar-sun: "\ea44";
$macicons-azul-flake: "\ea45";
$macicons-back-forth: "\ea46";
$macicons-back-pain: "\ea47";
$macicons-backstab: "\ea48";
$macicons-backup: "\ea49";
$macicons-ball-glow: "\ea4a";
$macicons-ballista: "\ea4b";
$macicons-bandage-roll: "\ea4c";
$macicons-banging-gavel: "\ea4d";
$macicons-banjo: "\ea4e";
$macicons-baobab: "\ea4f";
$macicons-barbarian: "\ea50";
$macicons-barbed-arrow: "\ea51";
$macicons-barbed-coil: "\ea52";
$macicons-barbed-nails: "\ea53";
$macicons-barbed-spear: "\ea54";
$macicons-barbed-star: "\ea55";
$macicons-barbed-sun: "\ea56";
$macicons-barbed-wire: "\ea57";
$macicons-barbute: "\ea58";
$macicons-barefoot: "\ea59";
$macicons-baseball-bat: "\ea5a";
$macicons-bat-blade: "\ea5b";
$macicons-baton: "\ea5c";
$macicons-battered-axe: "\ea5d";
$macicons-battle-axe: "\ea5e";
$macicons-battle-gear: "\ea5f";
$macicons-bayonet: "\ea60";
$macicons-beam-wake: "\ea61";
$macicons-beams-aura: "\ea62";
$macicons-beard: "\ea63";
$macicons-beer-horn: "\ea64";
$macicons-bell-shield: "\ea65";
$macicons-belt: "\ea66";
$macicons-beveled-star: "\ea67";
$macicons-biceps: "\ea68";
$macicons-big-wave: "\ea69";
$macicons-biohazard: "\ea6a";
$macicons-bird-claw: "\ea6b";
$macicons-bird-twitter: "\ea6c";
$macicons-black-bar: "\ea6d";
$macicons-black-belt: "\ea6e";
$macicons-black-book: "\ea6f";
$macicons-black-hand-shield: "\ea70";
$macicons-black-hole-bolas: "\ea71";
$macicons-blackball: "\ea72";
$macicons-blackcurrant: "\ea73";
$macicons-blacksmith: "\ea74";
$macicons-blade-bite: "\ea75";
$macicons-blade-drag: "\ea76";
$macicons-blade-fall: "\ea77";
$macicons-blast: "\ea78";
$macicons-bleeding-eye: "\ea79";
$macicons-bleeding-heart: "\ea7a";
$macicons-bleeding-wound: "\ea7b";
$macicons-blindfold: "\ea7c";
$macicons-bloody-sword: "\ea7d";
$macicons-blunderbuss: "\ea7e";
$macicons-bo: "\ea7f";
$macicons-body-balance: "\ea80";
$macicons-body-swapping: "\ea81";
$macicons-bolas: "\ea82";
$macicons-bolt-drop: "\ea83";
$macicons-bolt-eye: "\ea84";
$macicons-bolt-shield: "\ea85";
$macicons-bolt-spell-cast: "\ea86";
$macicons-bone-knife: "\ea87";
$macicons-book-aura: "\ea88";
$macicons-book-cover-2: "\ea89";
$macicons-book-cover: "\ea8a";
$macicons-book-storm: "\ea8b";
$macicons-bookmarklet: "\ea8c";
$macicons-boomerang-cross: "\ea8d";
$macicons-boomerang-sun: "\ea8e";
$macicons-boomerang: "\ea8f";
$macicons-boot-kick: "\ea90";
$macicons-bordered-shield: "\ea91";
$macicons-boss-key: "\ea92";
$macicons-bottle-vapors: "\ea93";
$macicons-bouncing-sword: "\ea94";
$macicons-bow-string: "\ea95";
$macicons-bowie-knife-2: "\ea96";
$macicons-bowie-knife: "\ea97";
$macicons-bowman: "\ea98";
$macicons-box-unpacking: "\ea99";
$macicons-bracers: "\ea9a";
$macicons-brain-dump: "\ea9b";
$macicons-brain-freeze: "\ea9c";
$macicons-brain: "\ea9d";
$macicons-brainstorm: "\ea9e";
$macicons-branch-arrow: "\ea9f";
$macicons-brandy-bottle: "\eaa0";
$macicons-brasero: "\eaa1";
$macicons-brass-eye: "\eaa2";
$macicons-brass-knuckles: "\eaa3";
$macicons-breaking-chain: "\eaa4";
$macicons-bright-explosion: "\eaa5";
$macicons-broad-dagger: "\eaa6";
$macicons-broadhead-arrow: "\eaa7";
$macicons-broadsword: "\eaa8";
$macicons-broken-axe: "\eaa9";
$macicons-broken-bottle: "\eaaa";
$macicons-broken-heart-zone: "\eaab";
$macicons-broken-heart: "\eaac";
$macicons-broken-shield: "\eaad";
$macicons-broken-skull: "\eaae";
$macicons-broom: "\eaaf";
$macicons-brute: "\eab0";
$macicons-bubbles: "\eab1";
$macicons-bubbling-beam: "\eab2";
$macicons-bubbling-flask: "\eab3";
$macicons-bullet-impacts: "\eab4";
$macicons-bullets: "\eab5";
$macicons-bullseye: "\eab6";
$macicons-bully-minion: "\eab7";
$macicons-burning-blobs: "\eab8";
$macicons-burning-book: "\eab9";
$macicons-burning-dot: "\eaba";
$macicons-burning-embers: "\eabb";
$macicons-burning-eye: "\eabc";
$macicons-burning-forest: "\eabd";
$macicons-burning-meteor: "\eabe";
$macicons-burning-passion: "\eabf";
$macicons-burning-round-shot: "\eac0";
$macicons-burning-skull: "\eac1";
$macicons-burning-tree: "\eac2";
$macicons-burst-blob: "\eac3";
$macicons-butterfly-knife: "\eac4";
$macicons-buy-card: "\eac5";
$macicons-caesar: "\eac6";
$macicons-campfire: "\eac7";
$macicons-candle-holder: "\eac8";
$macicons-candle-light: "\eac9";
$macicons-candles: "\eaca";
$macicons-candy-canes: "\eacb";
$macicons-cannon-ball: "\eacc";
$macicons-cannon-shot: "\eacd";
$macicons-cantua: "\eace";
$macicons-cape-armor: "\eacf";
$macicons-cardboard-box-closed: "\ead0";
$macicons-cardboard-box: "\ead1";
$macicons-carrot: "\ead2";
$macicons-cash: "\ead3";
$macicons-castle-2: "\ead4";
$macicons-cauldron-2: "\ead5";
$macicons-cauldron: "\ead6";
$macicons-celebration-fire: "\ead7";
$macicons-centaur-heart: "\ead8";
$macicons-chain-lightning: "\ead9";
$macicons-chained-heart: "\eada";
$macicons-chakram: "\eadb";
$macicons-chalice-drops: "\eadc";
$macicons-character: "\eadd";
$macicons-charged-arrow: "\eade";
$macicons-chat-bubble: "\eadf";
$macicons-checkered-diamond: "\eae0";
$macicons-checklist: "\eae1";
$macicons-cheerful: "\eae2";
$macicons-chemical-arrow: "\eae3";
$macicons-chemical-bolt: "\eae4";
$macicons-chemical-drop: "\eae5";
$macicons-chest-armor: "\eae6";
$macicons-chest: "\eae7";
$macicons-circle-sparks: "\eae8";
$macicons-classical-knowledge: "\eae9";
$macicons-clay-brick: "\eaea";
$macicons-cloak-dagger: "\eaeb";
$macicons-clockwork: "\eaec";
$macicons-closed-doors: "\eaed";
$macicons-cloth-jar: "\eaee";
$macicons-cloud-ring: "\eaef";
$macicons-clover: "\eaf0";
$macicons-coal-pile: "\eaf1";
$macicons-coiled-nail: "\eaf2";
$macicons-coinflip: "\eaf3";
$macicons-coins-pile: "\eaf4";
$macicons-coins: "\eaf5";
$macicons-cold-heart: "\eaf6";
$macicons-comet-spark: "\eaf7";
$macicons-compass: "\eaf8";
$macicons-concentration-orb: "\eaf9";
$macicons-conqueror: "\eafa";
$macicons-coral: "\eafb";
$macicons-corner-explosion: "\eafc";
$macicons-cosmic-egg: "\eafd";
$macicons-cracked-disc: "\eafe";
$macicons-cracked-glass: "\eaff";
$macicons-cracked-helm: "\eb00";
$macicons-cracked-mask: "\eb01";
$macicons-cracked-shield: "\eb02";
$macicons-crescent-blade-2: "\eb03";
$macicons-crescent-blade: "\eb04";
$macicons-crescent-staff: "\eb05";
$macicons-croc-sword: "\eb06";
$macicons-cross-mark: "\eb07";
$macicons-cross-shield: "\eb08";
$macicons-crossbow: "\eb09";
$macicons-crossed-axes: "\eb0a";
$macicons-crossed-sabres: "\eb0b";
$macicons-crossed-swords: "\eb0c";
$macicons-crosshair-arrow: "\eb0d";
$macicons-crown-of-thorns: "\eb0e";
$macicons-crown: "\eb0f";
$macicons-crowned-heart: "\eb10";
$macicons-crowned-skull: "\eb11";
$macicons-crumbling-ball: "\eb12";
$macicons-crystal-ball: "\eb13";
$macicons-crystal-bars: "\eb14";
$macicons-crystal-cluster: "\eb15";
$macicons-crystal-eye: "\eb16";
$macicons-crystal-growth: "\eb17";
$macicons-crystal-shine: "\eb18";
$macicons-crystal-wand: "\eb19";
$macicons-crystalize: "\eb1a";
$macicons-cubes: "\eb1b";
$macicons-cupidon-arrow: "\eb1c";
$macicons-curled-tentacle: "\eb1d";
$macicons-curling-vines: "\eb1e";
$macicons-cursed-star: "\eb1f";
$macicons-curvy-knife: "\eb20";
$macicons-cut-diamond: "\eb21";
$macicons-cut-palm: "\eb22";
$macicons-daemon-pull: "\eb23";
$macicons-dagger-rose: "\eb24";
$macicons-daggers: "\eb25";
$macicons-dandelion-flower: "\eb26";
$macicons-dark-squad: "\eb27";
$macicons-dart: "\eb28";
$macicons-dead-eye: "\eb29";
$macicons-dead-wood: "\eb2a";
$macicons-deadly-strike: "\eb2b";
$macicons-death-juice: "\eb2c";
$macicons-death-note: "\eb2d";
$macicons-death-star: "\eb2e";
$macicons-death-zone: "\eb2f";
$macicons-decapitation: "\eb30";
$macicons-defense-satellite: "\eb31";
$macicons-defibrilate: "\eb32";
$macicons-dervish-swords: "\eb33";
$macicons-desert: "\eb34";
$macicons-despair: "\eb35";
$macicons-devil-mask: "\eb36";
$macicons-dew: "\eb37";
$macicons-dial-padlock: "\eb38";
$macicons-diamond-hard: "\eb39";
$macicons-diamonds-smile: "\eb3a";
$macicons-dig-dug: "\eb3b";
$macicons-digital-trace: "\eb3c";
$macicons-discobolus: "\eb3d";
$macicons-diving-dagger: "\eb3e";
$macicons-dna1: "\eb3f";
$macicons-dna2: "\eb40";
$macicons-dodging: "\eb41";
$macicons-doorway: "\eb42";
$macicons-double-ringed-orb: "\eb43";
$macicons-double-shot: "\eb44";
$macicons-dove: "\eb45";
$macicons-dragon-breath: "\eb46";
$macicons-dragon-spiral: "\eb47";
$macicons-drakkar: "\eb48";
$macicons-drink-me: "\eb49";
$macicons-drinking: "\eb4a";
$macicons-dripping-blade: "\eb4b";
$macicons-dripping-goo: "\eb4c";
$macicons-dripping-knife: "\eb4d";
$macicons-dripping-sword: "\eb4e";
$macicons-drop-earrings: "\eb4f";
$macicons-drop-weapon: "\eb50";
$macicons-drop: "\eb51";
$macicons-droplet-splash: "\eb52";
$macicons-droplets: "\eb53";
$macicons-drowning: "\eb54";
$macicons-duality: "\eb55";
$macicons-duration: "\eb56";
$macicons-dust-cloud: "\eb57";
$macicons-eagle-emblem: "\eb58";
$macicons-earth-crack: "\eb59";
$macicons-earth-spit: "\eb5a";
$macicons-echo-ripples: "\eb5b";
$macicons-eclipse-flare: "\eb5c";
$macicons-eclipse-saw: "\eb5d";
$macicons-eclipse: "\eb5e";
$macicons-edged-shield: "\eb5f";
$macicons-egg-defense: "\eb60";
$macicons-egg-eye: "\eb61";
$macicons-elderberry: "\eb62";
$macicons-electric-whip: "\eb63";
$macicons-electric: "\eb64";
$macicons-electrical-crescent: "\eb65";
$macicons-elysium-shade: "\eb66";
$macicons-ember-shot: "\eb67";
$macicons-embrassed-energy: "\eb68";
$macicons-emerald: "\eb69";
$macicons-empty-hourglass: "\eb6a";
$macicons-energy-arrow: "\eb6b";
$macicons-energy-breath: "\eb6c";
$macicons-energy-shield: "\eb6d";
$macicons-energy-sword: "\eb6e";
$macicons-enlightenment: "\eb6f";
$macicons-enrage: "\eb70";
$macicons-eruption: "\eb71";
$macicons-evil-bat: "\eb72";
$macicons-evil-eyes: "\eb73";
$macicons-evil-love: "\eb74";
$macicons-evil-wings: "\eb75";
$macicons-executioner-hood: "\eb76";
$macicons-expense: "\eb77";
$macicons-explosion-rays: "\eb78";
$macicons-explosive-materials: "\eb79";
$macicons-explosive-meeting: "\eb7a";
$macicons-extra-lucid: "\eb7b";
$macicons-extra-time: "\eb7c";
$macicons-extraction-orb: "\eb7d";
$macicons-eye-shield: "\eb7e";
$macicons-eye-target: "\eb7f";
$macicons-eyedropper: "\eb80";
$macicons-fairy-wand: "\eb81";
$macicons-fall-down: "\eb82";
$macicons-falling-blob: "\eb83";
$macicons-falling-bomb: "\eb84";
$macicons-falling-boulder: "\eb85";
$macicons-falling-eye: "\eb86";
$macicons-falling-leaf: "\eb87";
$macicons-falling-ovoid: "\eb88";
$macicons-falling-rocks: "\eb89";
$macicons-falling-star: "\eb8a";
$macicons-fangs: "\eb8b";
$macicons-fast-arrow: "\eb8c";
$macicons-fern: "\eb8d";
$macicons-fire-bomb: "\eb8e";
$macicons-fire-bottle: "\eb8f";
$macicons-fire-bowl: "\eb90";
$macicons-fire-breath: "\eb91";
$macicons-fire-dash: "\eb92";
$macicons-fire-gem: "\eb93";
$macicons-fire-punch: "\eb94";
$macicons-fire-ray: "\eb95";
$macicons-fire-ring: "\eb96";
$macicons-fire-shield: "\eb97";
$macicons-fire-shrine: "\eb98";
$macicons-fire-silhouette: "\eb99";
$macicons-fire-spell-cast: "\eb9a";
$macicons-fire-tail: "\eb9b";
$macicons-fire-wave: "\eb9c";
$macicons-fire-zone: "\eb9d";
$macicons-fire: "\eb9e";
$macicons-fireflake: "\eb9f";
$macicons-firewall: "\eba0";
$macicons-fish-scales: "\eba1";
$macicons-fist: "\eba2";
$macicons-fizzing-flask: "\eba3";
$macicons-flail: "\eba4";
$macicons-flame-claws: "\eba5";
$macicons-flame-spin: "\eba6";
$macicons-flame-tunnel: "\eba7";
$macicons-flame: "\eba8";
$macicons-flamed-leaf: "\eba9";
$macicons-flaming-arrow: "\ebaa";
$macicons-flaming-claw: "\ebab";
$macicons-flaming-sheet: "\ebac";
$macicons-flaming-trident: "\ebad";
$macicons-flanged-mace: "\ebae";
$macicons-flat-hammer: "\ebaf";
$macicons-flat-star: "\ebb0";
$macicons-floating-ghost: "\ebb1";
$macicons-floating-tentacles: "\ebb2";
$macicons-fluffy-cloud: "\ebb3";
$macicons-fluffy-swirl: "\ebb4";
$macicons-fluffy-trefoil: "\ebb5";
$macicons-fluffy-wing: "\ebb6";
$macicons-flying-dagger: "\ebb7";
$macicons-foam: "\ebb8";
$macicons-foamy-disc: "\ebb9";
$macicons-focused-lightning: "\ebba";
$macicons-foot-trip: "\ebbb";
$macicons-forest-entrance: "\ebbc";
$macicons-fragmented-meteor: "\ebbd";
$macicons-fragmented-sword: "\ebbe";
$macicons-frostfire: "\ebbf";
$macicons-frozen-arrow: "\ebc0";
$macicons-frozen-block: "\ebc1";
$macicons-frozen-body: "\ebc2";
$macicons-frozen-orb: "\ebc3";
$macicons-frozen-ring: "\ebc4";
$macicons-galaxy: "\ebc5";
$macicons-gavel: "\ebc6";
$macicons-gear-stick-pattern: "\ebc7";
$macicons-gem-chain: "\ebc8";
$macicons-gems: "\ebc9";
$macicons-ghost-ally: "\ebca";
$macicons-ghost: "\ebcb";
$macicons-gift-of-knowledge: "\ebcc";
$macicons-gift-trap: "\ebcd";
$macicons-gladius: "\ebce";
$macicons-glaive: "\ebcf";
$macicons-glass-ball: "\ebd0";
$macicons-glass-heart: "\ebd1";
$macicons-glowing-artifact: "\ebd2";
$macicons-glowing-hands: "\ebd3";
$macicons-goal-keeper: "\ebd4";
$macicons-goo-explosion: "\ebd5";
$macicons-goo-spurt: "\ebd6";
$macicons-gooey-eyed-sun: "\ebd7";
$macicons-gooey-impact: "\ebd8";
$macicons-gooey-molecule: "\ebd9";
$macicons-gooey-sword: "\ebda";
$macicons-grab: "\ebdb";
$macicons-grapple: "\ebdc";
$macicons-grass-mushroom: "\ebdd";
$macicons-greaves: "\ebde";
$macicons-grenade: "\ebdf";
$macicons-groundbreaker: "\ebe0";
$macicons-guitar: "\ebe1";
$macicons-gyroscope: "\ebe2";
$macicons-hades-symbol: "\ebe3";
$macicons-halberd: "\ebe4";
$macicons-hammer-break: "\ebe5";
$macicons-hammer-drop: "\ebe6";
$macicons-hand-wing: "\ebe7";
$macicons-hand: "\ebe8";
$macicons-harpoon-chain: "\ebe9";
$macicons-healing: "\ebea";
$macicons-health-decrease: "\ebeb";
$macicons-health-increase: "\ebec";
$macicons-health-potion: "\ebed";
$macicons-heart-armor: "\ebee";
$macicons-heart-beats: "\ebef";
$macicons-heart-bottle: "\ebf0";
$macicons-heart-key: "\ebf1";
$macicons-heart-shield: "\ebf2";
$macicons-heart-stake: "\ebf3";
$macicons-heartburn: "\ebf4";
$macicons-heat-haze: "\ebf5";
$macicons-heaven-gate: "\ebf6";
$macicons-heavy-arrow: "\ebf7";
$macicons-heavy-fall: "\ebf8";
$macicons-heavy-lightning: "\ebf9";
$macicons-heavy-rain: "\ebfa";
$macicons-heavy-thorny-triskelion: "\ebfb";
$macicons-helmet-head-shot: "\ebfc";
$macicons-heptagram: "\ebfd";
$macicons-heraldic-sun: "\ebfe";
$macicons-herbs-bundle: "\ebff";
$macicons-hidden: "\ec00";
$macicons-high-kick: "\ec01";
$macicons-high-punch: "\ec02";
$macicons-high-shot: "\ec03";
$macicons-hiking: "\ec04";
$macicons-hive-mind: "\ec05";
$macicons-holy-grail: "\ec06";
$macicons-holy-oak: "\ec07";
$macicons-holy-symbol: "\ec08";
$macicons-holy-water: "\ec09";
$macicons-hood: "\ec0a";
$macicons-hooded-assassin: "\ec0b";
$macicons-hooded-figure: "\ec0c";
$macicons-human-target: "\ec0d";
$macicons-hunting-horn: "\ec0e";
$macicons-hypersonic-bolt: "\ec0f";
$macicons-hypersonic-melon: "\ec10";
$macicons-icarus: "\ec11";
$macicons-ice-bolt: "\ec12";
$macicons-ice-bomb: "\ec13";
$macicons-ice-golem: "\ec14";
$macicons-ice-iris: "\ec15";
$macicons-ice-shield: "\ec16";
$macicons-ice-spear: "\ec17";
$macicons-ice-spell-cast: "\ec18";
$macicons-icicles-fence: "\ec19";
$macicons-ifrit: "\ec1a";
$macicons-impact-point: "\ec1b";
$macicons-implosion: "\ec1c";
$macicons-imprisoned: "\ec1d";
$macicons-incense: "\ec1e";
$macicons-incoming-rocket: "\ec1f";
$macicons-infested-mass: "\ec20";
$macicons-infinity: "\ec21";
$macicons-ink-swirl: "\ec22";
$macicons-interleaved-arrows: "\ec23";
$macicons-interleaved-claws: "\ec24";
$macicons-internal-injury: "\ec25";
$macicons-internal-organ: "\ec26";
$macicons-intricate-necklace: "\ec27";
$macicons-invisible: "\ec28";
$macicons-ion-cannon-blast: "\ec29";
$macicons-iron-cross: "\ec2a";
$macicons-jeweled-chalice: "\ec2b";
$macicons-journey: "\ec2c";
$macicons-juggler: "\ec2d";
$macicons-jungle: "\ec2e";
$macicons-kaleidoscope-pearls: "\ec2f";
$macicons-katana: "\ec30";
$macicons-ketchup: "\ec31";
$macicons-kevlar: "\ec32";
$macicons-kimono: "\ec33";
$macicons-kindle: "\ec34";
$macicons-kitchen-knives: "\ec35";
$macicons-knapsack: "\ec36";
$macicons-knee-bandage: "\ec37";
$macicons-kneeling: "\ec38";
$macicons-knife-thrust: "\ec39";
$macicons-knocked-out-stars: "\ec3a";
$macicons-knockout: "\ec3b";
$macicons-kusarigama: "\ec3c";
$macicons-ladder: "\ec3d";
$macicons-lamellar: "\ec3e";
$macicons-lantern-flame: "\ec3f";
$macicons-large-wound: "\ec40";
$macicons-laser-blast: "\ec41";
$macicons-laser-burst: "\ec42";
$macicons-laser-precision: "\ec43";
$macicons-laser-sparks: "\ec44";
$macicons-laserburn: "\ec45";
$macicons-laurel-crown: "\ec46";
$macicons-lava: "\ec47";
$macicons-leaf-swirl: "\ec48";
$macicons-leapfrog: "\ec49";
$macicons-leg-armor: "\ec4a";
$macicons-letter-bomb: "\ec4b";
$macicons-life-bar: "\ec4c";
$macicons-life-in-the-balance: "\ec4d";
$macicons-lift: "\ec4e";
$macicons-light-sabers: "\ec4f";
$macicons-light-thorny-triskelion: "\ec50";
$macicons-lightning-arc: "\ec51";
$macicons-lightning-bow: "\ec52";
$macicons-lightning-branches: "\ec53";
$macicons-lightning-dissipation: "\ec54";
$macicons-lightning-electron: "\ec55";
$macicons-lightning-flame: "\ec56";
$macicons-lightning-frequency: "\ec57";
$macicons-lightning-helix: "\ec58";
$macicons-lightning-mask: "\ec59";
$macicons-lightning-saber: "\ec5a";
$macicons-lightning-shadow: "\ec5b";
$macicons-lightning-shield: "\ec5c";
$macicons-lightning-shout: "\ec5d";
$macicons-lightning-slashes: "\ec5e";
$macicons-lightning-spanner: "\ec5f";
$macicons-lightning-storm: "\ec60";
$macicons-lightning-tear: "\ec61";
$macicons-lightning-tree: "\ec62";
$macicons-lightning-trio: "\ec63";
$macicons-linked-rings: "\ec64";
$macicons-lizardman: "\ec65";
$macicons-locked-box: "\ec66";
$macicons-log: "\ec67";
$macicons-logging: "\ec68";
$macicons-look-at: "\ec69";
$macicons-lotus-flower: "\ec6a";
$macicons-lucifer-cannon: "\ec6b";
$macicons-lunar-wand: "\ec6c";
$macicons-lyre: "\ec6d";
$macicons-mace-head: "\ec6e";
$macicons-machete: "\ec6f";
$macicons-magic-axe: "\ec70";
$macicons-magic-broom: "\ec71";
$macicons-magic-gate: "\ec72";
$macicons-magic-palm: "\ec73";
$macicons-magic-shield: "\ec74";
$macicons-magic-swirl: "\ec75";
$macicons-magic-trident: "\ec76";
$macicons-magick-trick: "\ec77";
$macicons-magnet-blast: "\ec78";
$macicons-magnet: "\ec79";
$macicons-magnifying-glass: "\ec7a";
$macicons-mailbox: "\ec7b";
$macicons-mailed-fist: "\ec7c";
$macicons-manacles: "\ec7d";
$macicons-marble-tap: "\ec7e";
$macicons-marshmallows: "\ec7f";
$macicons-martyr-memorial: "\ec80";
$macicons-mason-jar: "\ec81";
$macicons-master-of-arms: "\ec82";
$macicons-match-tip: "\ec83";
$macicons-matter-states: "\ec84";
$macicons-meal: "\ec85";
$macicons-meat-cleaver: "\ec86";
$macicons-meat-hook: "\ec87";
$macicons-meat: "\ec88";
$macicons-mechanical-arm: "\ec89";
$macicons-meditation: "\ec8a";
$macicons-medusa-head: "\ec8b";
$macicons-melting-ice-cube: "\ec8c";
$macicons-melting-metal: "\ec8d";
$macicons-mermaid: "\ec8e";
$macicons-metal-bar: "\ec8f";
$macicons-metal-boot: "\ec90";
$macicons-meteor-impact: "\ec91";
$macicons-middle-arrow: "\ec92";
$macicons-mighty-force: "\ec93";
$macicons-mighty-horn: "\ec94";
$macicons-mine-explosion: "\ec95";
$macicons-miner: "\ec96";
$macicons-mineral-heart: "\ec97";
$macicons-mineral-pearls: "\ec98";
$macicons-minerals: "\ec99";
$macicons-minions: "\ec9a";
$macicons-miracle-medecine: "\ec9b";
$macicons-mirror-mirror: "\ec9c";
$macicons-misdirection: "\ec9d";
$macicons-missile-swarm: "\ec9e";
$macicons-moebius-star: "\ec9f";
$macicons-moebius-trefoil: "\eca0";
$macicons-molecule: "\eca1";
$macicons-morph-ball: "\eca2";
$macicons-mountain-climbing: "\eca3";
$macicons-mouth-watering: "\eca4";
$macicons-music-spell: "\eca5";
$macicons-musical-notes: "\eca6";
$macicons-musical-score: "\eca7";
$macicons-necklace: "\eca8";
$macicons-nectar: "\eca9";
$macicons-nested-eclipses: "\ecaa";
$macicons-nested-hearts: "\ecab";
$macicons-new-shoot: "\ecac";
$macicons-night-sleep: "\ecad";
$macicons-north-star-shuriken: "\ecae";
$macicons-notebook: "\ecaf";
$macicons-ocarina: "\ecb0";
$macicons-on-sight: "\ecb1";
$macicons-on-target: "\ecb2";
$macicons-one-eyed: "\ecb3";
$macicons-ophanim: "\ecb4";
$macicons-oppression: "\ecb5";
$macicons-orb-wand: "\ecb6";
$macicons-orbital: "\ecb7";
$macicons-ore: "\ecb8";
$macicons-organigram: "\ecb9";
$macicons-orrery: "\ecba";
$macicons-overdose: "\ecbb";
$macicons-overdrive: "\ecbc";
$macicons-overmind: "\ecbd";
$macicons-owl: "\ecbe";
$macicons-packed-planks: "\ecbf";
$macicons-padlock-open: "\ecc0";
$macicons-padlock: "\ecc1";
$macicons-pay-money: "\ecc2";
$macicons-pendant-key: "\ecc3";
$macicons-pentarrows-tornado: "\ecc4";
$macicons-person: "\ecc5";
$macicons-pierced-body: "\ecc6";
$macicons-pierced-heart: "\ecc7";
$macicons-piercing-sword: "\ecc8";
$macicons-pill-drop: "\ecc9";
$macicons-plain-dagger: "\ecca";
$macicons-plant-seed: "\eccb";
$macicons-plant-watering: "\eccc";
$macicons-plasma-bolt: "\eccd";
$macicons-player-base: "\ecce";
$macicons-player-time: "\eccf";
$macicons-pocket-bow: "\ecd0";
$macicons-pointing: "\ecd1";
$macicons-pointy-hat: "\ecd2";
$macicons-pointy-sword: "\ecd3";
$macicons-poison-bottle: "\ecd4";
$macicons-poison-gas: "\ecd5";
$macicons-polar-bear: "\ecd6";
$macicons-polar-star: "\ecd7";
$macicons-portal: "\ecd8";
$macicons-potion-ball: "\ecd9";
$macicons-pouring-chalice: "\ecda";
$macicons-pouring-pot: "\ecdb";
$macicons-powder: "\ecdc";
$macicons-power-lightning: "\ecdd";
$macicons-power-ring: "\ecde";
$macicons-prayer-beads: "\ecdf";
$macicons-prayer: "\ece0";
$macicons-pretty-fangs: "\ece1";
$macicons-prism: "\ece2";
$macicons-profit: "\ece3";
$macicons-psychic-waves: "\ece4";
$macicons-pulse: "\ece5";
$macicons-punch-blast: "\ece6";
$macicons-punch: "\ece7";
$macicons-pyromaniac: "\ece8";
$macicons-qaitbay-citadel: "\ece9";
$macicons-quake-stomp: "\ecea";
$macicons-quicksand: "\eceb";
$macicons-quiver: "\ecec";
$macicons-radiations: "\eced";
$macicons-rainbow-star: "\ecee";
$macicons-rat: "\ecef";
$macicons-rear-aura: "\ecf0";
$macicons-receive-money: "\ecf1";
$macicons-regeneration: "\ecf2";
$macicons-relic-blade: "\ecf3";
$macicons-remedy: "\ecf4";
$macicons-resonance: "\ecf5";
$macicons-reticule: "\ecf6";
$macicons-return-arrow: "\ecf7";
$macicons-revolt: "\ecf8";
$macicons-ribbon-shield: "\ecf9";
$macicons-ricochet: "\ecfa";
$macicons-ringed-beam: "\ecfb";
$macicons-riposte: "\ecfc";
$macicons-robe: "\ecfd";
$macicons-rock-2: "\ecfe";
$macicons-rolling-bomb: "\ecff";
$macicons-rolling-dices: "\ed00";
$macicons-rolling-energy: "\ed01";
$macicons-rope-dart: "\ed02";
$macicons-rosa-shield: "\ed03";
$macicons-rose: "\ed04";
$macicons-round-potion: "\ed05";
$macicons-round-shield: "\ed06";
$macicons-round-struck: "\ed07";
$macicons-round-table: "\ed08";
$macicons-rub-el-hizb: "\ed09";
$macicons-rune-stone: "\ed0a";
$macicons-rune-sword: "\ed0b";
$macicons-running-ninja: "\ed0c";
$macicons-rusty-sword: "\ed0d";
$macicons-saber-and-pistol: "\ed0e";
$macicons-saber-slash: "\ed0f";
$macicons-saber-tooth: "\ed10";
$macicons-sabers-choc: "\ed11";
$macicons-sacrificial-dagger: "\ed12";
$macicons-sai: "\ed13";
$macicons-salamander: "\ed14";
$macicons-sands-of-time: "\ed15";
$macicons-sandstorm: "\ed16";
$macicons-saphir: "\ed17";
$macicons-scabbard: "\ed18";
$macicons-screaming: "\ed19";
$macicons-scroll-quill: "\ed1a";
$macicons-scroll-unfurled: "\ed1b";
$macicons-sea-cliff: "\ed1c";
$macicons-secret-book: "\ed1d";
$macicons-secret-door: "\ed1e";
$macicons-self-love: "\ed1f";
$macicons-semi-closed-eye: "\ed20";
$macicons-shadow-follower: "\ed21";
$macicons-shambling-zombie: "\ed22";
$macicons-shamrock: "\ed23";
$macicons-shard-sword: "\ed24";
$macicons-sharp-axe: "\ed25";
$macicons-sharp-halberd: "\ed26";
$macicons-shatter: "\ed27";
$macicons-shattered-heart: "\ed28";
$macicons-shield-bash: "\ed29";
$macicons-shield-bounces: "\ed2a";
$macicons-shield-disabled: "\ed2b";
$macicons-shield-echoes: "\ed2c";
$macicons-shield-impact: "\ed2d";
$macicons-shield-opposition: "\ed2e";
$macicons-shield-reflect: "\ed2f";
$macicons-shield: "\ed30";
$macicons-shining-heart: "\ed31";
$macicons-shining-sword: "\ed32";
$macicons-shouting: "\ed33";
$macicons-sickle: "\ed34";
$macicons-silver-bullet: "\ed35";
$macicons-sing: "\ed36";
$macicons-sinusoidal-beam: "\ed37";
$macicons-skills: "\ed38";
$macicons-skull-bolt: "\ed39";
$macicons-skull-shield: "\ed3a";
$macicons-skull-staff: "\ed3b";
$macicons-slap: "\ed3c";
$macicons-slashed-shield: "\ed3d";
$macicons-slavery-whip: "\ed3e";
$macicons-slicing-arrow: "\ed3f";
$macicons-small-fire: "\ed40";
$macicons-smart: "\ed41";
$macicons-smoking-finger: "\ed42";
$macicons-smoking-orb: "\ed43";
$macicons-snake-jar: "\ed44";
$macicons-snow-bottle: "\ed45";
$macicons-snowflake-2: "\ed46";
$macicons-snowing: "\ed47";
$macicons-snowman: "\ed48";
$macicons-solar-time: "\ed49";
$macicons-sonic-boom: "\ed4a";
$macicons-sonic-lightning: "\ed4b";
$macicons-sonic-screech: "\ed4c";
$macicons-sonic-shout: "\ed4d";
$macicons-spark-spirit: "\ed4e";
$macicons-sparkles: "\ed4f";
$macicons-sparkling-sabre: "\ed50";
$macicons-sparky-bomb: "\ed51";
$macicons-spear-feather: "\ed52";
$macicons-spectre: "\ed53";
$macicons-spell-book: "\ed54";
$macicons-spider-alt: "\ed55";
$macicons-spider-web: "\ed56";
$macicons-spiked-dragon-head: "\ed57";
$macicons-spiked-shield: "\ed58";
$macicons-spiked-tail: "\ed59";
$macicons-spiked-wall: "\ed5a";
$macicons-spiky-wing: "\ed5b";
$macicons-spinning-blades: "\ed5c";
$macicons-spinning-ribbons: "\ed5d";
$macicons-spinning-sword: "\ed5e";
$macicons-spiral-arrow: "\ed5f";
$macicons-spiral-bottle: "\ed60";
$macicons-spiral-tentacle: "\ed61";
$macicons-spiral-thrust: "\ed62";
$macicons-sprint: "\ed63";
$macicons-spyglass: "\ed64";
$macicons-square-bottle: "\ed65";
$macicons-star-formation: "\ed66";
$macicons-star-key: "\ed67";
$macicons-star-sattelites: "\ed68";
$macicons-star-skull: "\ed69";
$macicons-star-swirl: "\ed6a";
$macicons-stars-stack: "\ed6b";
$macicons-staryu: "\ed6c";
$macicons-static-waves: "\ed6d";
$macicons-static: "\ed6e";
$macicons-steam-blast: "\ed6f";
$macicons-steam: "\ed70";
$macicons-stiletto-2: "\ed71";
$macicons-stiletto: "\ed72";
$macicons-stomp-tornado: "\ed73";
$macicons-stone-axe: "\ed74";
$macicons-stone-spear: "\ed75";
$macicons-striking-arrows: "\ed76";
$macicons-striking-balls: "\ed77";
$macicons-striking-clamps: "\ed78";
$macicons-striking-diamonds: "\ed79";
$macicons-striking-splinter: "\ed7a";
$macicons-striped-sword: "\ed7b";
$macicons-strong: "\ed7c";
$macicons-stump-regrowth: "\ed7d";
$macicons-sun-priest: "\ed7e";
$macicons-sun-radiations: "\ed7f";
$macicons-sunbeams: "\ed80";
$macicons-supersonic-arrow: "\ed81";
$macicons-supersonic-bullet: "\ed82";
$macicons-surprised: "\ed83";
$macicons-surrounded-shield: "\ed84";
$macicons-suspicious: "\ed85";
$macicons-swap-bag: "\ed86";
$macicons-swirl-ring: "\ed87";
$macicons-swirl-string: "\ed88";
$macicons-switch-weapon: "\ed89";
$macicons-sword-altar: "\ed8a";
$macicons-sword-array: "\ed8b";
$macicons-sword-brandish: "\ed8c";
$macicons-sword-break: "\ed8d";
$macicons-sword-clash: "\ed8e";
$macicons-sword-mold: "\ed8f";
$macicons-sword-smithing: "\ed90";
$macicons-sword-spin: "\ed91";
$macicons-sword-tie: "\ed92";
$macicons-sword-wound: "\ed93";
$macicons-swords-emblem: "\ed94";
$macicons-swords-power: "\ed95";
$macicons-take-my-money: "\ed96";
$macicons-target-arrows: "\ed97";
$macicons-target-dummy: "\ed98";
$macicons-target-poster: "\ed99";
$macicons-target-prize: "\ed9a";
$macicons-targeting: "\ed9b";
$macicons-tavern-sign: "\ed9c";
$macicons-telepathy: "\ed9d";
$macicons-teleport: "\ed9e";
$macicons-templar-eye: "\ed9f";
$macicons-temporary-shield: "\eda0";
$macicons-tension-snowflake: "\eda1";
$macicons-tentacle-heart: "\eda2";
$macicons-tesla-coil: "\eda3";
$macicons-third-eye: "\eda4";
$macicons-thor-fist: "\eda5";
$macicons-thorn-helix: "\eda6";
$macicons-thorned-arrow: "\eda7";
$macicons-three-friends: "\eda8";
$macicons-throwing-ball: "\eda9";
$macicons-thrown-charcoal: "\edaa";
$macicons-thrown-daggers: "\edab";
$macicons-thrown-knife: "\edac";
$macicons-thrown-spear: "\edad";
$macicons-thrust-bend: "\edae";
$macicons-thunder-blade: "\edaf";
$macicons-thunder-skull: "\edb0";
$macicons-thunder-struck: "\edb1";
$macicons-thunderball: "\edb2";
$macicons-tiara: "\edb3";
$macicons-tied-scroll: "\edb4";
$macicons-tiger-head: "\edb5";
$macicons-tightrope: "\edb6";
$macicons-time-trap: "\edb7";
$macicons-tired-eye: "\edb8";
$macicons-torch: "\edb9";
$macicons-tornado-discs: "\edba";
$macicons-tornado: "\edbb";
$macicons-trade: "\edbc";
$macicons-transfuse: "\edbd";
$macicons-transportation-rings: "\edbe";
$macicons-tree-branch: "\edbf";
$macicons-tribal-shield: "\edc0";
$macicons-trident-shield: "\edc1";
$macicons-truce: "\edc2";
$macicons-trumpet-flag: "\edc3";
$macicons-tumbleweed: "\edc4";
$macicons-turd: "\edc5";
$macicons-turtle-shell: "\edc6";
$macicons-twin-shell: "\edc7";
$macicons-twister: "\edc8";
$macicons-two-coins: "\edc9";
$macicons-two-shadows: "\edca";
$macicons-ultrasound: "\edcb";
$macicons-uncertainty: "\edcc";
$macicons-unfriendly-fire: "\edcd";
$macicons-unlocking: "\edce";
$macicons-unstable-orb: "\edcf";
$macicons-unstable-projectile: "\edd0";
$macicons-uprising: "\edd1";
$macicons-usable: "\edd2";
$macicons-vibrating-ball: "\edd3";
$macicons-vibrating-shield: "\edd4";
$macicons-vine-whip: "\edd5";
$macicons-vines: "\edd6";
$macicons-vortex: "\edd7";
$macicons-vuvuzelas: "\edd8";
$macicons-warhammer: "\edd9";
$macicons-warlock-eye: "\edda";
$macicons-warlord-helmet: "\eddb";
$macicons-water-bolt: "\eddc";
$macicons-water-recycling: "\eddd";
$macicons-water-splash: "\edde";
$macicons-waterfall: "\eddf";
$macicons-wave-crest: "\ede0";
$macicons-wave-strike: "\ede1";
$macicons-waves: "\ede2";
$macicons-web-spit: "\ede3";
$macicons-whirlwind: "\ede4";
$macicons-wildfires: "\ede5";
$macicons-willow-tree: "\ede6";
$macicons-wind-hole: "\ede7";
$macicons-wind-slap: "\ede8";
$macicons-wing-cloak: "\ede9";
$macicons-winged-arrow: "\edea";
$macicons-winged-scepter: "\edeb";
$macicons-winged-shield: "\edec";
$macicons-winged-sword: "\eded";
$macicons-wingfoot: "\edee";
$macicons-wisdom: "\edef";
$macicons-wolf-head: "\edf0";
$macicons-wood-beam: "\edf1";
$macicons-worried-eyes: "\edf2";
$macicons-yarn: "\edf3";
$macicons-yin-yang: "\edf4";
$macicons-zebra-shield: "\edf5";
$macicons-zeus-sword: "\edf6";

@font-face {
    font-family: macicons;
    src: url("/assets/fonts/macicons.eot");
    src: url("/assets/fonts/macicons.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/macicons.woff2") format("woff2"), url("/assets/fonts/macicons.woff") format("woff"), url("/assets/fonts/macicons.ttf") format("truetype"), url("/assets/fonts/macicons.svg#macicons") format("svg");
    font-style: normal;
    font-weight: 400;
}

%macicons {
    display: inline-block;
    transform: translate(0, 0);
    text-rendering: auto;
    font: normal normal 400 14px/1 macicons;
    font-size: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.macicons {
    @extend %macicons;
}

.macicons-lg {
    vertical-align: -15%;
    line-height: 0.75em;
    font-size: 1.33333333em;
}

.macicons-2x {
    font-size: 2em;
}

.macicons-3x {
    font-size: 3em;
}

.macicons-4x {
    font-size: 4em;
}

.macicons-5x {
    font-size: 5em;
}

.macicons-fw {
    width: 1.28571429em;
    text-align: center;
}

.macicons-3d-stairs::before {
    content: "\ea01";
}

.macicons-abdominal-armor::before {
    content: "\ea02";
}

.macicons-abstract-005::before {
    content: "\ea03";
}

.macicons-abstract-007::before {
    content: "\ea04";
}

.macicons-abstract-010::before {
    content: "\ea05";
}

.macicons-abstract-011::before {
    content: "\ea06";
}

.macicons-abstract-024::before {
    content: "\ea07";
}

.macicons-abstract-027::before {
    content: "\ea08";
}

.macicons-abstract-033::before {
    content: "\ea09";
}

.macicons-abstract-034::before {
    content: "\ea0a";
}

.macicons-abstract-038::before {
    content: "\ea0b";
}

.macicons-abstract-041::before {
    content: "\ea0c";
}

.macicons-abstract-047::before {
    content: "\ea0d";
}

.macicons-abstract-053::before {
    content: "\ea0e";
}

.macicons-abstract-066::before {
    content: "\ea0f";
}

.macicons-abstract-071::before {
    content: "\ea10";
}

.macicons-abstract-088::before {
    content: "\ea11";
}

.macicons-abstract-097::before {
    content: "\ea12";
}

.macicons-achilles-heel::before {
    content: "\ea13";
}

.macicons-acorn::before {
    content: "\ea14";
}

.macicons-ages::before {
    content: "\ea15";
}

.macicons-air-zigzag::before {
    content: "\ea16";
}

.macicons-alien-fire::before {
    content: "\ea17";
}

.macicons-all-for-one::before {
    content: "\ea18";
}

.macicons-all-seeing-eye::before {
    content: "\ea19";
}

.macicons-alligator-clip::before {
    content: "\ea1a";
}

.macicons-amplitude::before {
    content: "\ea1b";
}

.macicons-anarchy::before {
    content: "\ea1c";
}

.macicons-ancient-sword::before {
    content: "\ea1d";
}

.macicons-angel-outfit::before {
    content: "\ea1e";
}

.macicons-angry-eyes::before {
    content: "\ea1f";
}

.macicons-ankh::before {
    content: "\ea20";
}

.macicons-anvil-impact::before {
    content: "\ea21";
}

.macicons-anvil::before {
    content: "\ea22";
}

.macicons-apothecary::before {
    content: "\ea23";
}

.macicons-archer::before {
    content: "\ea24";
}

.macicons-archive-register::before {
    content: "\ea25";
}

.macicons-archive-research::before {
    content: "\ea26";
}

.macicons-arcing-bolt::before {
    content: "\ea27";
}

.macicons-armor-downgrade::before {
    content: "\ea28";
}

.macicons-armor-punch::before {
    content: "\ea29";
}

.macicons-armor-upgrade::before {
    content: "\ea2a";
}

.macicons-armor-vest::before {
    content: "\ea2b";
}

.macicons-arrow-cluster::before {
    content: "\ea2c";
}

.macicons-arrow-flights::before {
    content: "\ea2d";
}

.macicons-arrow-scope::before {
    content: "\ea2e";
}

.macicons-arrowed::before {
    content: "\ea2f";
}

.macicons-arrowhead::before {
    content: "\ea30";
}

.macicons-arrows-shield::before {
    content: "\ea31";
}

.macicons-artichoke::before {
    content: "\ea32";
}

.macicons-artificial-hive::before {
    content: "\ea33";
}

.macicons-artillery-shell::before {
    content: "\ea34";
}

.macicons-assassin-pocket::before {
    content: "\ea35";
}

.macicons-astrolabe::before {
    content: "\ea36";
}

.macicons-astronaut-helmet::before {
    content: "\ea37";
}

.macicons-at-sea::before {
    content: "\ea38";
}

.macicons-atlas::before {
    content: "\ea39";
}

.macicons-atom-core::before {
    content: "\ea3a";
}

.macicons-atomic-slashes::before {
    content: "\ea3b";
}

.macicons-attached-shield::before {
    content: "\ea3c";
}

.macicons-aura::before {
    content: "\ea3d";
}

.macicons-auto-repair::before {
    content: "\ea3e";
}

.macicons-autogun::before {
    content: "\ea3f";
}

.macicons-awareness::before {
    content: "\ea40";
}

.macicons-axe-in-stump::before {
    content: "\ea41";
}

.macicons-axe-swing::before {
    content: "\ea42";
}

.macicons-axe-sword::before {
    content: "\ea43";
}

.macicons-aztec-calendar-sun::before {
    content: "\ea44";
}

.macicons-azul-flake::before {
    content: "\ea45";
}

.macicons-back-forth::before {
    content: "\ea46";
}

.macicons-back-pain::before {
    content: "\ea47";
}

.macicons-backstab::before {
    content: "\ea48";
}

.macicons-backup::before {
    content: "\ea49";
}

.macicons-ball-glow::before {
    content: "\ea4a";
}

.macicons-ballista::before {
    content: "\ea4b";
}

.macicons-bandage-roll::before {
    content: "\ea4c";
}

.macicons-banging-gavel::before {
    content: "\ea4d";
}

.macicons-banjo::before {
    content: "\ea4e";
}

.macicons-baobab::before {
    content: "\ea4f";
}

.macicons-barbarian::before {
    content: "\ea50";
}

.macicons-barbed-arrow::before {
    content: "\ea51";
}

.macicons-barbed-coil::before {
    content: "\ea52";
}

.macicons-barbed-nails::before {
    content: "\ea53";
}

.macicons-barbed-spear::before {
    content: "\ea54";
}

.macicons-barbed-star::before {
    content: "\ea55";
}

.macicons-barbed-sun::before {
    content: "\ea56";
}

.macicons-barbed-wire::before {
    content: "\ea57";
}

.macicons-barbute::before {
    content: "\ea58";
}

.macicons-barefoot::before {
    content: "\ea59";
}

.macicons-baseball-bat::before {
    content: "\ea5a";
}

.macicons-bat-blade::before {
    content: "\ea5b";
}

.macicons-baton::before {
    content: "\ea5c";
}

.macicons-battered-axe::before {
    content: "\ea5d";
}

.macicons-battle-axe::before {
    content: "\ea5e";
}

.macicons-battle-gear::before {
    content: "\ea5f";
}

.macicons-bayonet::before {
    content: "\ea60";
}

.macicons-beam-wake::before {
    content: "\ea61";
}

.macicons-beams-aura::before {
    content: "\ea62";
}

.macicons-beard::before {
    content: "\ea63";
}

.macicons-beer-horn::before {
    content: "\ea64";
}

.macicons-bell-shield::before {
    content: "\ea65";
}

.macicons-belt::before {
    content: "\ea66";
}

.macicons-beveled-star::before {
    content: "\ea67";
}

.macicons-biceps::before {
    content: "\ea68";
}

.macicons-big-wave::before {
    content: "\ea69";
}

.macicons-biohazard::before {
    content: "\ea6a";
}

.macicons-bird-claw::before {
    content: "\ea6b";
}

.macicons-bird-twitter::before {
    content: "\ea6c";
}

.macicons-black-bar::before {
    content: "\ea6d";
}

.macicons-black-belt::before {
    content: "\ea6e";
}

.macicons-black-book::before {
    content: "\ea6f";
}

.macicons-black-hand-shield::before {
    content: "\ea70";
}

.macicons-black-hole-bolas::before {
    content: "\ea71";
}

.macicons-blackball::before {
    content: "\ea72";
}

.macicons-blackcurrant::before {
    content: "\ea73";
}

.macicons-blacksmith::before {
    content: "\ea74";
}

.macicons-blade-bite::before {
    content: "\ea75";
}

.macicons-blade-drag::before {
    content: "\ea76";
}

.macicons-blade-fall::before {
    content: "\ea77";
}

.macicons-blast::before {
    content: "\ea78";
}

.macicons-bleeding-eye::before {
    content: "\ea79";
}

.macicons-bleeding-heart::before {
    content: "\ea7a";
}

.macicons-bleeding-wound::before {
    content: "\ea7b";
}

.macicons-blindfold::before {
    content: "\ea7c";
}

.macicons-bloody-sword::before {
    content: "\ea7d";
}

.macicons-blunderbuss::before {
    content: "\ea7e";
}

.macicons-bo::before {
    content: "\ea7f";
}

.macicons-body-balance::before {
    content: "\ea80";
}

.macicons-body-swapping::before {
    content: "\ea81";
}

.macicons-bolas::before {
    content: "\ea82";
}

.macicons-bolt-drop::before {
    content: "\ea83";
}

.macicons-bolt-eye::before {
    content: "\ea84";
}

.macicons-bolt-shield::before {
    content: "\ea85";
}

.macicons-bolt-spell-cast::before {
    content: "\ea86";
}

.macicons-bone-knife::before {
    content: "\ea87";
}

.macicons-book-aura::before {
    content: "\ea88";
}

.macicons-book-cover-2::before {
    content: "\ea89";
}

.macicons-book-cover::before {
    content: "\ea8a";
}

.macicons-book-storm::before {
    content: "\ea8b";
}

.macicons-bookmarklet::before {
    content: "\ea8c";
}

.macicons-boomerang-cross::before {
    content: "\ea8d";
}

.macicons-boomerang-sun::before {
    content: "\ea8e";
}

.macicons-boomerang::before {
    content: "\ea8f";
}

.macicons-boot-kick::before {
    content: "\ea90";
}

.macicons-bordered-shield::before {
    content: "\ea91";
}

.macicons-boss-key::before {
    content: "\ea92";
}

.macicons-bottle-vapors::before {
    content: "\ea93";
}

.macicons-bouncing-sword::before {
    content: "\ea94";
}

.macicons-bow-string::before {
    content: "\ea95";
}

.macicons-bowie-knife-2::before {
    content: "\ea96";
}

.macicons-bowie-knife::before {
    content: "\ea97";
}

.macicons-bowman::before {
    content: "\ea98";
}

.macicons-box-unpacking::before {
    content: "\ea99";
}

.macicons-bracers::before {
    content: "\ea9a";
}

.macicons-brain-dump::before {
    content: "\ea9b";
}

.macicons-brain-freeze::before {
    content: "\ea9c";
}

.macicons-brain::before {
    content: "\ea9d";
}

.macicons-brainstorm::before {
    content: "\ea9e";
}

.macicons-branch-arrow::before {
    content: "\ea9f";
}

.macicons-brandy-bottle::before {
    content: "\eaa0";
}

.macicons-brasero::before {
    content: "\eaa1";
}

.macicons-brass-eye::before {
    content: "\eaa2";
}

.macicons-brass-knuckles::before {
    content: "\eaa3";
}

.macicons-breaking-chain::before {
    content: "\eaa4";
}

.macicons-bright-explosion::before {
    content: "\eaa5";
}

.macicons-broad-dagger::before {
    content: "\eaa6";
}

.macicons-broadhead-arrow::before {
    content: "\eaa7";
}

.macicons-broadsword::before {
    content: "\eaa8";
}

.macicons-broken-axe::before {
    content: "\eaa9";
}

.macicons-broken-bottle::before {
    content: "\eaaa";
}

.macicons-broken-heart-zone::before {
    content: "\eaab";
}

.macicons-broken-heart::before {
    content: "\eaac";
}

.macicons-broken-shield::before {
    content: "\eaad";
}

.macicons-broken-skull::before {
    content: "\eaae";
}

.macicons-broom::before {
    content: "\eaaf";
}

.macicons-brute::before {
    content: "\eab0";
}

.macicons-bubbles::before {
    content: "\eab1";
}

.macicons-bubbling-beam::before {
    content: "\eab2";
}

.macicons-bubbling-flask::before {
    content: "\eab3";
}

.macicons-bullet-impacts::before {
    content: "\eab4";
}

.macicons-bullets::before {
    content: "\eab5";
}

.macicons-bullseye::before {
    content: "\eab6";
}

.macicons-bully-minion::before {
    content: "\eab7";
}

.macicons-burning-blobs::before {
    content: "\eab8";
}

.macicons-burning-book::before {
    content: "\eab9";
}

.macicons-burning-dot::before {
    content: "\eaba";
}

.macicons-burning-embers::before {
    content: "\eabb";
}

.macicons-burning-eye::before {
    content: "\eabc";
}

.macicons-burning-forest::before {
    content: "\eabd";
}

.macicons-burning-meteor::before {
    content: "\eabe";
}

.macicons-burning-passion::before {
    content: "\eabf";
}

.macicons-burning-round-shot::before {
    content: "\eac0";
}

.macicons-burning-skull::before {
    content: "\eac1";
}

.macicons-burning-tree::before {
    content: "\eac2";
}

.macicons-burst-blob::before {
    content: "\eac3";
}

.macicons-butterfly-knife::before {
    content: "\eac4";
}

.macicons-buy-card::before {
    content: "\eac5";
}

.macicons-caesar::before {
    content: "\eac6";
}

.macicons-campfire::before {
    content: "\eac7";
}

.macicons-candle-holder::before {
    content: "\eac8";
}

.macicons-candle-light::before {
    content: "\eac9";
}

.macicons-candles::before {
    content: "\eaca";
}

.macicons-candy-canes::before {
    content: "\eacb";
}

.macicons-cannon-ball::before {
    content: "\eacc";
}

.macicons-cannon-shot::before {
    content: "\eacd";
}

.macicons-cantua::before {
    content: "\eace";
}

.macicons-cape-armor::before {
    content: "\eacf";
}

.macicons-cardboard-box-closed::before {
    content: "\ead0";
}

.macicons-cardboard-box::before {
    content: "\ead1";
}

.macicons-carrot::before {
    content: "\ead2";
}

.macicons-cash::before {
    content: "\ead3";
}

.macicons-castle-2::before {
    content: "\ead4";
}

.macicons-cauldron-2::before {
    content: "\ead5";
}

.macicons-cauldron::before {
    content: "\ead6";
}

.macicons-celebration-fire::before {
    content: "\ead7";
}

.macicons-centaur-heart::before {
    content: "\ead8";
}

.macicons-chain-lightning::before {
    content: "\ead9";
}

.macicons-chained-heart::before {
    content: "\eada";
}

.macicons-chakram::before {
    content: "\eadb";
}

.macicons-chalice-drops::before {
    content: "\eadc";
}

.macicons-character::before {
    content: "\eadd";
}

.macicons-charged-arrow::before {
    content: "\eade";
}

.macicons-chat-bubble::before {
    content: "\eadf";
}

.macicons-checkered-diamond::before {
    content: "\eae0";
}

.macicons-checklist::before {
    content: "\eae1";
}

.macicons-cheerful::before {
    content: "\eae2";
}

.macicons-chemical-arrow::before {
    content: "\eae3";
}

.macicons-chemical-bolt::before {
    content: "\eae4";
}

.macicons-chemical-drop::before {
    content: "\eae5";
}

.macicons-chest-armor::before {
    content: "\eae6";
}

.macicons-chest::before {
    content: "\eae7";
}

.macicons-circle-sparks::before {
    content: "\eae8";
}

.macicons-classical-knowledge::before {
    content: "\eae9";
}

.macicons-clay-brick::before {
    content: "\eaea";
}

.macicons-cloak-dagger::before {
    content: "\eaeb";
}

.macicons-clockwork::before {
    content: "\eaec";
}

.macicons-closed-doors::before {
    content: "\eaed";
}

.macicons-cloth-jar::before {
    content: "\eaee";
}

.macicons-cloud-ring::before {
    content: "\eaef";
}

.macicons-clover::before {
    content: "\eaf0";
}

.macicons-coal-pile::before {
    content: "\eaf1";
}

.macicons-coiled-nail::before {
    content: "\eaf2";
}

.macicons-coinflip::before {
    content: "\eaf3";
}

.macicons-coins-pile::before {
    content: "\eaf4";
}

.macicons-coins::before {
    content: "\eaf5";
}

.macicons-cold-heart::before {
    content: "\eaf6";
}

.macicons-comet-spark::before {
    content: "\eaf7";
}

.macicons-compass::before {
    content: "\eaf8";
}

.macicons-concentration-orb::before {
    content: "\eaf9";
}

.macicons-conqueror::before {
    content: "\eafa";
}

.macicons-coral::before {
    content: "\eafb";
}

.macicons-corner-explosion::before {
    content: "\eafc";
}

.macicons-cosmic-egg::before {
    content: "\eafd";
}

.macicons-cracked-disc::before {
    content: "\eafe";
}

.macicons-cracked-glass::before {
    content: "\eaff";
}

.macicons-cracked-helm::before {
    content: "\eb00";
}

.macicons-cracked-mask::before {
    content: "\eb01";
}

.macicons-cracked-shield::before {
    content: "\eb02";
}

.macicons-crescent-blade-2::before {
    content: "\eb03";
}

.macicons-crescent-blade::before {
    content: "\eb04";
}

.macicons-crescent-staff::before {
    content: "\eb05";
}

.macicons-croc-sword::before {
    content: "\eb06";
}

.macicons-cross-mark::before {
    content: "\eb07";
}

.macicons-cross-shield::before {
    content: "\eb08";
}

.macicons-crossbow::before {
    content: "\eb09";
}

.macicons-crossed-axes::before {
    content: "\eb0a";
}

.macicons-crossed-sabres::before {
    content: "\eb0b";
}

.macicons-crossed-swords::before {
    content: "\eb0c";
}

.macicons-crosshair-arrow::before {
    content: "\eb0d";
}

.macicons-crown-of-thorns::before {
    content: "\eb0e";
}

.macicons-crown::before {
    content: "\eb0f";
}

.macicons-crowned-heart::before {
    content: "\eb10";
}

.macicons-crowned-skull::before {
    content: "\eb11";
}

.macicons-crumbling-ball::before {
    content: "\eb12";
}

.macicons-crystal-ball::before {
    content: "\eb13";
}

.macicons-crystal-bars::before {
    content: "\eb14";
}

.macicons-crystal-cluster::before {
    content: "\eb15";
}

.macicons-crystal-eye::before {
    content: "\eb16";
}

.macicons-crystal-growth::before {
    content: "\eb17";
}

.macicons-crystal-shine::before {
    content: "\eb18";
}

.macicons-crystal-wand::before {
    content: "\eb19";
}

.macicons-crystalize::before {
    content: "\eb1a";
}

.macicons-cubes::before {
    content: "\eb1b";
}

.macicons-cupidon-arrow::before {
    content: "\eb1c";
}

.macicons-curled-tentacle::before {
    content: "\eb1d";
}

.macicons-curling-vines::before {
    content: "\eb1e";
}

.macicons-cursed-star::before {
    content: "\eb1f";
}

.macicons-curvy-knife::before {
    content: "\eb20";
}

.macicons-cut-diamond::before {
    content: "\eb21";
}

.macicons-cut-palm::before {
    content: "\eb22";
}

.macicons-daemon-pull::before {
    content: "\eb23";
}

.macicons-dagger-rose::before {
    content: "\eb24";
}

.macicons-daggers::before {
    content: "\eb25";
}

.macicons-dandelion-flower::before {
    content: "\eb26";
}

.macicons-dark-squad::before {
    content: "\eb27";
}

.macicons-dart::before {
    content: "\eb28";
}

.macicons-dead-eye::before {
    content: "\eb29";
}

.macicons-dead-wood::before {
    content: "\eb2a";
}

.macicons-deadly-strike::before {
    content: "\eb2b";
}

.macicons-death-juice::before {
    content: "\eb2c";
}

.macicons-death-note::before {
    content: "\eb2d";
}

.macicons-death-star::before {
    content: "\eb2e";
}

.macicons-death-zone::before {
    content: "\eb2f";
}

.macicons-decapitation::before {
    content: "\eb30";
}

.macicons-defense-satellite::before {
    content: "\eb31";
}

.macicons-defibrilate::before {
    content: "\eb32";
}

.macicons-dervish-swords::before {
    content: "\eb33";
}

.macicons-desert::before {
    content: "\eb34";
}

.macicons-despair::before {
    content: "\eb35";
}

.macicons-devil-mask::before {
    content: "\eb36";
}

.macicons-dew::before {
    content: "\eb37";
}

.macicons-dial-padlock::before {
    content: "\eb38";
}

.macicons-diamond-hard::before {
    content: "\eb39";
}

.macicons-diamonds-smile::before {
    content: "\eb3a";
}

.macicons-dig-dug::before {
    content: "\eb3b";
}

.macicons-digital-trace::before {
    content: "\eb3c";
}

.macicons-discobolus::before {
    content: "\eb3d";
}

.macicons-diving-dagger::before {
    content: "\eb3e";
}

.macicons-dna1::before {
    content: "\eb3f";
}

.macicons-dna2::before {
    content: "\eb40";
}

.macicons-dodging::before {
    content: "\eb41";
}

.macicons-doorway::before {
    content: "\eb42";
}

.macicons-double-ringed-orb::before {
    content: "\eb43";
}

.macicons-double-shot::before {
    content: "\eb44";
}

.macicons-dove::before {
    content: "\eb45";
}

.macicons-dragon-breath::before {
    content: "\eb46";
}

.macicons-dragon-spiral::before {
    content: "\eb47";
}

.macicons-drakkar::before {
    content: "\eb48";
}

.macicons-drink-me::before {
    content: "\eb49";
}

.macicons-drinking::before {
    content: "\eb4a";
}

.macicons-dripping-blade::before {
    content: "\eb4b";
}

.macicons-dripping-goo::before {
    content: "\eb4c";
}

.macicons-dripping-knife::before {
    content: "\eb4d";
}

.macicons-dripping-sword::before {
    content: "\eb4e";
}

.macicons-drop-earrings::before {
    content: "\eb4f";
}

.macicons-drop-weapon::before {
    content: "\eb50";
}

.macicons-drop::before {
    content: "\eb51";
}

.macicons-droplet-splash::before {
    content: "\eb52";
}

.macicons-droplets::before {
    content: "\eb53";
}

.macicons-drowning::before {
    content: "\eb54";
}

.macicons-duality::before {
    content: "\eb55";
}

.macicons-duration::before {
    content: "\eb56";
}

.macicons-dust-cloud::before {
    content: "\eb57";
}

.macicons-eagle-emblem::before {
    content: "\eb58";
}

.macicons-earth-crack::before {
    content: "\eb59";
}

.macicons-earth-spit::before {
    content: "\eb5a";
}

.macicons-echo-ripples::before {
    content: "\eb5b";
}

.macicons-eclipse-flare::before {
    content: "\eb5c";
}

.macicons-eclipse-saw::before {
    content: "\eb5d";
}

.macicons-eclipse::before {
    content: "\eb5e";
}

.macicons-edged-shield::before {
    content: "\eb5f";
}

.macicons-egg-defense::before {
    content: "\eb60";
}

.macicons-egg-eye::before {
    content: "\eb61";
}

.macicons-elderberry::before {
    content: "\eb62";
}

.macicons-electric-whip::before {
    content: "\eb63";
}

.macicons-electric::before {
    content: "\eb64";
}

.macicons-electrical-crescent::before {
    content: "\eb65";
}

.macicons-elysium-shade::before {
    content: "\eb66";
}

.macicons-ember-shot::before {
    content: "\eb67";
}

.macicons-embrassed-energy::before {
    content: "\eb68";
}

.macicons-emerald::before {
    content: "\eb69";
}

.macicons-empty-hourglass::before {
    content: "\eb6a";
}

.macicons-energy-arrow::before {
    content: "\eb6b";
}

.macicons-energy-breath::before {
    content: "\eb6c";
}

.macicons-energy-shield::before {
    content: "\eb6d";
}

.macicons-energy-sword::before {
    content: "\eb6e";
}

.macicons-enlightenment::before {
    content: "\eb6f";
}

.macicons-enrage::before {
    content: "\eb70";
}

.macicons-eruption::before {
    content: "\eb71";
}

.macicons-evil-bat::before {
    content: "\eb72";
}

.macicons-evil-eyes::before {
    content: "\eb73";
}

.macicons-evil-love::before {
    content: "\eb74";
}

.macicons-evil-wings::before {
    content: "\eb75";
}

.macicons-executioner-hood::before {
    content: "\eb76";
}

.macicons-expense::before {
    content: "\eb77";
}

.macicons-explosion-rays::before {
    content: "\eb78";
}

.macicons-explosive-materials::before {
    content: "\eb79";
}

.macicons-explosive-meeting::before {
    content: "\eb7a";
}

.macicons-extra-lucid::before {
    content: "\eb7b";
}

.macicons-extra-time::before {
    content: "\eb7c";
}

.macicons-extraction-orb::before {
    content: "\eb7d";
}

.macicons-eye-shield::before {
    content: "\eb7e";
}

.macicons-eye-target::before {
    content: "\eb7f";
}

.macicons-eyedropper::before {
    content: "\eb80";
}

.macicons-fairy-wand::before {
    content: "\eb81";
}

.macicons-fall-down::before {
    content: "\eb82";
}

.macicons-falling-blob::before {
    content: "\eb83";
}

.macicons-falling-bomb::before {
    content: "\eb84";
}

.macicons-falling-boulder::before {
    content: "\eb85";
}

.macicons-falling-eye::before {
    content: "\eb86";
}

.macicons-falling-leaf::before {
    content: "\eb87";
}

.macicons-falling-ovoid::before {
    content: "\eb88";
}

.macicons-falling-rocks::before {
    content: "\eb89";
}

.macicons-falling-star::before {
    content: "\eb8a";
}

.macicons-fangs::before {
    content: "\eb8b";
}

.macicons-fast-arrow::before {
    content: "\eb8c";
}

.macicons-fern::before {
    content: "\eb8d";
}

.macicons-fire-bomb::before {
    content: "\eb8e";
}

.macicons-fire-bottle::before {
    content: "\eb8f";
}

.macicons-fire-bowl::before {
    content: "\eb90";
}

.macicons-fire-breath::before {
    content: "\eb91";
}

.macicons-fire-dash::before {
    content: "\eb92";
}

.macicons-fire-gem::before {
    content: "\eb93";
}

.macicons-fire-punch::before {
    content: "\eb94";
}

.macicons-fire-ray::before {
    content: "\eb95";
}

.macicons-fire-ring::before {
    content: "\eb96";
}

.macicons-fire-shield::before {
    content: "\eb97";
}

.macicons-fire-shrine::before {
    content: "\eb98";
}

.macicons-fire-silhouette::before {
    content: "\eb99";
}

.macicons-fire-spell-cast::before {
    content: "\eb9a";
}

.macicons-fire-tail::before {
    content: "\eb9b";
}

.macicons-fire-wave::before {
    content: "\eb9c";
}

.macicons-fire-zone::before {
    content: "\eb9d";
}

.macicons-fire::before {
    content: "\eb9e";
}

.macicons-fireflake::before {
    content: "\eb9f";
}

.macicons-firewall::before {
    content: "\eba0";
}

.macicons-fish-scales::before {
    content: "\eba1";
}

.macicons-fist::before {
    content: "\eba2";
}

.macicons-fizzing-flask::before {
    content: "\eba3";
}

.macicons-flail::before {
    content: "\eba4";
}

.macicons-flame-claws::before {
    content: "\eba5";
}

.macicons-flame-spin::before {
    content: "\eba6";
}

.macicons-flame-tunnel::before {
    content: "\eba7";
}

.macicons-flame::before {
    content: "\eba8";
}

.macicons-flamed-leaf::before {
    content: "\eba9";
}

.macicons-flaming-arrow::before {
    content: "\ebaa";
}

.macicons-flaming-claw::before {
    content: "\ebab";
}

.macicons-flaming-sheet::before {
    content: "\ebac";
}

.macicons-flaming-trident::before {
    content: "\ebad";
}

.macicons-flanged-mace::before {
    content: "\ebae";
}

.macicons-flat-hammer::before {
    content: "\ebaf";
}

.macicons-flat-star::before {
    content: "\ebb0";
}

.macicons-floating-ghost::before {
    content: "\ebb1";
}

.macicons-floating-tentacles::before {
    content: "\ebb2";
}

.macicons-fluffy-cloud::before {
    content: "\ebb3";
}

.macicons-fluffy-swirl::before {
    content: "\ebb4";
}

.macicons-fluffy-trefoil::before {
    content: "\ebb5";
}

.macicons-fluffy-wing::before {
    content: "\ebb6";
}

.macicons-flying-dagger::before {
    content: "\ebb7";
}

.macicons-foam::before {
    content: "\ebb8";
}

.macicons-foamy-disc::before {
    content: "\ebb9";
}

.macicons-focused-lightning::before {
    content: "\ebba";
}

.macicons-foot-trip::before {
    content: "\ebbb";
}

.macicons-forest-entrance::before {
    content: "\ebbc";
}

.macicons-fragmented-meteor::before {
    content: "\ebbd";
}

.macicons-fragmented-sword::before {
    content: "\ebbe";
}

.macicons-frostfire::before {
    content: "\ebbf";
}

.macicons-frozen-arrow::before {
    content: "\ebc0";
}

.macicons-frozen-block::before {
    content: "\ebc1";
}

.macicons-frozen-body::before {
    content: "\ebc2";
}

.macicons-frozen-orb::before {
    content: "\ebc3";
}

.macicons-frozen-ring::before {
    content: "\ebc4";
}

.macicons-galaxy::before {
    content: "\ebc5";
}

.macicons-gavel::before {
    content: "\ebc6";
}

.macicons-gear-stick-pattern::before {
    content: "\ebc7";
}

.macicons-gem-chain::before {
    content: "\ebc8";
}

.macicons-gems::before {
    content: "\ebc9";
}

.macicons-ghost-ally::before {
    content: "\ebca";
}

.macicons-ghost::before {
    content: "\ebcb";
}

.macicons-gift-of-knowledge::before {
    content: "\ebcc";
}

.macicons-gift-trap::before {
    content: "\ebcd";
}

.macicons-gladius::before {
    content: "\ebce";
}

.macicons-glaive::before {
    content: "\ebcf";
}

.macicons-glass-ball::before {
    content: "\ebd0";
}

.macicons-glass-heart::before {
    content: "\ebd1";
}

.macicons-glowing-artifact::before {
    content: "\ebd2";
}

.macicons-glowing-hands::before {
    content: "\ebd3";
}

.macicons-goal-keeper::before {
    content: "\ebd4";
}

.macicons-goo-explosion::before {
    content: "\ebd5";
}

.macicons-goo-spurt::before {
    content: "\ebd6";
}

.macicons-gooey-eyed-sun::before {
    content: "\ebd7";
}

.macicons-gooey-impact::before {
    content: "\ebd8";
}

.macicons-gooey-molecule::before {
    content: "\ebd9";
}

.macicons-gooey-sword::before {
    content: "\ebda";
}

.macicons-grab::before {
    content: "\ebdb";
}

.macicons-grapple::before {
    content: "\ebdc";
}

.macicons-grass-mushroom::before {
    content: "\ebdd";
}

.macicons-greaves::before {
    content: "\ebde";
}

.macicons-grenade::before {
    content: "\ebdf";
}

.macicons-groundbreaker::before {
    content: "\ebe0";
}

.macicons-guitar::before {
    content: "\ebe1";
}

.macicons-gyroscope::before {
    content: "\ebe2";
}

.macicons-hades-symbol::before {
    content: "\ebe3";
}

.macicons-halberd::before {
    content: "\ebe4";
}

.macicons-hammer-break::before {
    content: "\ebe5";
}

.macicons-hammer-drop::before {
    content: "\ebe6";
}

.macicons-hand-wing::before {
    content: "\ebe7";
}

.macicons-hand::before {
    content: "\ebe8";
}

.macicons-harpoon-chain::before {
    content: "\ebe9";
}

.macicons-healing::before {
    content: "\ebea";
}

.macicons-health-decrease::before {
    content: "\ebeb";
}

.macicons-health-increase::before {
    content: "\ebec";
}

.macicons-health-potion::before {
    content: "\ebed";
}

.macicons-heart-armor::before {
    content: "\ebee";
}

.macicons-heart-beats::before {
    content: "\ebef";
}

.macicons-heart-bottle::before {
    content: "\ebf0";
}

.macicons-heart-key::before {
    content: "\ebf1";
}

.macicons-heart-shield::before {
    content: "\ebf2";
}

.macicons-heart-stake::before {
    content: "\ebf3";
}

.macicons-heartburn::before {
    content: "\ebf4";
}

.macicons-heat-haze::before {
    content: "\ebf5";
}

.macicons-heaven-gate::before {
    content: "\ebf6";
}

.macicons-heavy-arrow::before {
    content: "\ebf7";
}

.macicons-heavy-fall::before {
    content: "\ebf8";
}

.macicons-heavy-lightning::before {
    content: "\ebf9";
}

.macicons-heavy-rain::before {
    content: "\ebfa";
}

.macicons-heavy-thorny-triskelion::before {
    content: "\ebfb";
}

.macicons-helmet-head-shot::before {
    content: "\ebfc";
}

.macicons-heptagram::before {
    content: "\ebfd";
}

.macicons-heraldic-sun::before {
    content: "\ebfe";
}

.macicons-herbs-bundle::before {
    content: "\ebff";
}

.macicons-hidden::before {
    content: "\ec00";
}

.macicons-high-kick::before {
    content: "\ec01";
}

.macicons-high-punch::before {
    content: "\ec02";
}

.macicons-high-shot::before {
    content: "\ec03";
}

.macicons-hiking::before {
    content: "\ec04";
}

.macicons-hive-mind::before {
    content: "\ec05";
}

.macicons-holy-grail::before {
    content: "\ec06";
}

.macicons-holy-oak::before {
    content: "\ec07";
}

.macicons-holy-symbol::before {
    content: "\ec08";
}

.macicons-holy-water::before {
    content: "\ec09";
}

.macicons-hood::before {
    content: "\ec0a";
}

.macicons-hooded-assassin::before {
    content: "\ec0b";
}

.macicons-hooded-figure::before {
    content: "\ec0c";
}

.macicons-human-target::before {
    content: "\ec0d";
}

.macicons-hunting-horn::before {
    content: "\ec0e";
}

.macicons-hypersonic-bolt::before {
    content: "\ec0f";
}

.macicons-hypersonic-melon::before {
    content: "\ec10";
}

.macicons-icarus::before {
    content: "\ec11";
}

.macicons-ice-bolt::before {
    content: "\ec12";
}

.macicons-ice-bomb::before {
    content: "\ec13";
}

.macicons-ice-golem::before {
    content: "\ec14";
}

.macicons-ice-iris::before {
    content: "\ec15";
}

.macicons-ice-shield::before {
    content: "\ec16";
}

.macicons-ice-spear::before {
    content: "\ec17";
}

.macicons-ice-spell-cast::before {
    content: "\ec18";
}

.macicons-icicles-fence::before {
    content: "\ec19";
}

.macicons-ifrit::before {
    content: "\ec1a";
}

.macicons-impact-point::before {
    content: "\ec1b";
}

.macicons-implosion::before {
    content: "\ec1c";
}

.macicons-imprisoned::before {
    content: "\ec1d";
}

.macicons-incense::before {
    content: "\ec1e";
}

.macicons-incoming-rocket::before {
    content: "\ec1f";
}

.macicons-infested-mass::before {
    content: "\ec20";
}

.macicons-infinity::before {
    content: "\ec21";
}

.macicons-ink-swirl::before {
    content: "\ec22";
}

.macicons-interleaved-arrows::before {
    content: "\ec23";
}

.macicons-interleaved-claws::before {
    content: "\ec24";
}

.macicons-internal-injury::before {
    content: "\ec25";
}

.macicons-internal-organ::before {
    content: "\ec26";
}

.macicons-intricate-necklace::before {
    content: "\ec27";
}

.macicons-invisible::before {
    content: "\ec28";
}

.macicons-ion-cannon-blast::before {
    content: "\ec29";
}

.macicons-iron-cross::before {
    content: "\ec2a";
}

.macicons-jeweled-chalice::before {
    content: "\ec2b";
}

.macicons-journey::before {
    content: "\ec2c";
}

.macicons-juggler::before {
    content: "\ec2d";
}

.macicons-jungle::before {
    content: "\ec2e";
}

.macicons-kaleidoscope-pearls::before {
    content: "\ec2f";
}

.macicons-katana::before {
    content: "\ec30";
}

.macicons-ketchup::before {
    content: "\ec31";
}

.macicons-kevlar::before {
    content: "\ec32";
}

.macicons-kimono::before {
    content: "\ec33";
}

.macicons-kindle::before {
    content: "\ec34";
}

.macicons-kitchen-knives::before {
    content: "\ec35";
}

.macicons-knapsack::before {
    content: "\ec36";
}

.macicons-knee-bandage::before {
    content: "\ec37";
}

.macicons-kneeling::before {
    content: "\ec38";
}

.macicons-knife-thrust::before {
    content: "\ec39";
}

.macicons-knocked-out-stars::before {
    content: "\ec3a";
}

.macicons-knockout::before {
    content: "\ec3b";
}

.macicons-kusarigama::before {
    content: "\ec3c";
}

.macicons-ladder::before {
    content: "\ec3d";
}

.macicons-lamellar::before {
    content: "\ec3e";
}

.macicons-lantern-flame::before {
    content: "\ec3f";
}

.macicons-large-wound::before {
    content: "\ec40";
}

.macicons-laser-blast::before {
    content: "\ec41";
}

.macicons-laser-burst::before {
    content: "\ec42";
}

.macicons-laser-precision::before {
    content: "\ec43";
}

.macicons-laser-sparks::before {
    content: "\ec44";
}

.macicons-laserburn::before {
    content: "\ec45";
}

.macicons-laurel-crown::before {
    content: "\ec46";
}

.macicons-lava::before {
    content: "\ec47";
}

.macicons-leaf-swirl::before {
    content: "\ec48";
}

.macicons-leapfrog::before {
    content: "\ec49";
}

.macicons-leg-armor::before {
    content: "\ec4a";
}

.macicons-letter-bomb::before {
    content: "\ec4b";
}

.macicons-life-bar::before {
    content: "\ec4c";
}

.macicons-life-in-the-balance::before {
    content: "\ec4d";
}

.macicons-lift::before {
    content: "\ec4e";
}

.macicons-light-sabers::before {
    content: "\ec4f";
}

.macicons-light-thorny-triskelion::before {
    content: "\ec50";
}

.macicons-lightning-arc::before {
    content: "\ec51";
}

.macicons-lightning-bow::before {
    content: "\ec52";
}

.macicons-lightning-branches::before {
    content: "\ec53";
}

.macicons-lightning-dissipation::before {
    content: "\ec54";
}

.macicons-lightning-electron::before {
    content: "\ec55";
}

.macicons-lightning-flame::before {
    content: "\ec56";
}

.macicons-lightning-frequency::before {
    content: "\ec57";
}

.macicons-lightning-helix::before {
    content: "\ec58";
}

.macicons-lightning-mask::before {
    content: "\ec59";
}

.macicons-lightning-saber::before {
    content: "\ec5a";
}

.macicons-lightning-shadow::before {
    content: "\ec5b";
}

.macicons-lightning-shield::before {
    content: "\ec5c";
}

.macicons-lightning-shout::before {
    content: "\ec5d";
}

.macicons-lightning-slashes::before {
    content: "\ec5e";
}

.macicons-lightning-spanner::before {
    content: "\ec5f";
}

.macicons-lightning-storm::before {
    content: "\ec60";
}

.macicons-lightning-tear::before {
    content: "\ec61";
}

.macicons-lightning-tree::before {
    content: "\ec62";
}

.macicons-lightning-trio::before {
    content: "\ec63";
}

.macicons-linked-rings::before {
    content: "\ec64";
}

.macicons-lizardman::before {
    content: "\ec65";
}

.macicons-locked-box::before {
    content: "\ec66";
}

.macicons-log::before {
    content: "\ec67";
}

.macicons-logging::before {
    content: "\ec68";
}

.macicons-look-at::before {
    content: "\ec69";
}

.macicons-lotus-flower::before {
    content: "\ec6a";
}

.macicons-lucifer-cannon::before {
    content: "\ec6b";
}

.macicons-lunar-wand::before {
    content: "\ec6c";
}

.macicons-lyre::before {
    content: "\ec6d";
}

.macicons-mace-head::before {
    content: "\ec6e";
}

.macicons-machete::before {
    content: "\ec6f";
}

.macicons-magic-axe::before {
    content: "\ec70";
}

.macicons-magic-broom::before {
    content: "\ec71";
}

.macicons-magic-gate::before {
    content: "\ec72";
}

.macicons-magic-palm::before {
    content: "\ec73";
}

.macicons-magic-shield::before {
    content: "\ec74";
}

.macicons-magic-swirl::before {
    content: "\ec75";
}

.macicons-magic-trident::before {
    content: "\ec76";
}

.macicons-magick-trick::before {
    content: "\ec77";
}

.macicons-magnet-blast::before {
    content: "\ec78";
}

.macicons-magnet::before {
    content: "\ec79";
}

.macicons-magnifying-glass::before {
    content: "\ec7a";
}

.macicons-mailbox::before {
    content: "\ec7b";
}

.macicons-mailed-fist::before {
    content: "\ec7c";
}

.macicons-manacles::before {
    content: "\ec7d";
}

.macicons-marble-tap::before {
    content: "\ec7e";
}

.macicons-marshmallows::before {
    content: "\ec7f";
}

.macicons-martyr-memorial::before {
    content: "\ec80";
}

.macicons-mason-jar::before {
    content: "\ec81";
}

.macicons-master-of-arms::before {
    content: "\ec82";
}

.macicons-match-tip::before {
    content: "\ec83";
}

.macicons-matter-states::before {
    content: "\ec84";
}

.macicons-meal::before {
    content: "\ec85";
}

.macicons-meat-cleaver::before {
    content: "\ec86";
}

.macicons-meat-hook::before {
    content: "\ec87";
}

.macicons-meat::before {
    content: "\ec88";
}

.macicons-mechanical-arm::before {
    content: "\ec89";
}

.macicons-meditation::before {
    content: "\ec8a";
}

.macicons-medusa-head::before {
    content: "\ec8b";
}

.macicons-melting-ice-cube::before {
    content: "\ec8c";
}

.macicons-melting-metal::before {
    content: "\ec8d";
}

.macicons-mermaid::before {
    content: "\ec8e";
}

.macicons-metal-bar::before {
    content: "\ec8f";
}

.macicons-metal-boot::before {
    content: "\ec90";
}

.macicons-meteor-impact::before {
    content: "\ec91";
}

.macicons-middle-arrow::before {
    content: "\ec92";
}

.macicons-mighty-force::before {
    content: "\ec93";
}

.macicons-mighty-horn::before {
    content: "\ec94";
}

.macicons-mine-explosion::before {
    content: "\ec95";
}

.macicons-miner::before {
    content: "\ec96";
}

.macicons-mineral-heart::before {
    content: "\ec97";
}

.macicons-mineral-pearls::before {
    content: "\ec98";
}

.macicons-minerals::before {
    content: "\ec99";
}

.macicons-minions::before {
    content: "\ec9a";
}

.macicons-miracle-medecine::before {
    content: "\ec9b";
}

.macicons-mirror-mirror::before {
    content: "\ec9c";
}

.macicons-misdirection::before {
    content: "\ec9d";
}

.macicons-missile-swarm::before {
    content: "\ec9e";
}

.macicons-moebius-star::before {
    content: "\ec9f";
}

.macicons-moebius-trefoil::before {
    content: "\eca0";
}

.macicons-molecule::before {
    content: "\eca1";
}

.macicons-morph-ball::before {
    content: "\eca2";
}

.macicons-mountain-climbing::before {
    content: "\eca3";
}

.macicons-mouth-watering::before {
    content: "\eca4";
}

.macicons-music-spell::before {
    content: "\eca5";
}

.macicons-musical-notes::before {
    content: "\eca6";
}

.macicons-musical-score::before {
    content: "\eca7";
}

.macicons-necklace::before {
    content: "\eca8";
}

.macicons-nectar::before {
    content: "\eca9";
}

.macicons-nested-eclipses::before {
    content: "\ecaa";
}

.macicons-nested-hearts::before {
    content: "\ecab";
}

.macicons-new-shoot::before {
    content: "\ecac";
}

.macicons-night-sleep::before {
    content: "\ecad";
}

.macicons-north-star-shuriken::before {
    content: "\ecae";
}

.macicons-notebook::before {
    content: "\ecaf";
}

.macicons-ocarina::before {
    content: "\ecb0";
}

.macicons-on-sight::before {
    content: "\ecb1";
}

.macicons-on-target::before {
    content: "\ecb2";
}

.macicons-one-eyed::before {
    content: "\ecb3";
}

.macicons-ophanim::before {
    content: "\ecb4";
}

.macicons-oppression::before {
    content: "\ecb5";
}

.macicons-orb-wand::before {
    content: "\ecb6";
}

.macicons-orbital::before {
    content: "\ecb7";
}

.macicons-ore::before {
    content: "\ecb8";
}

.macicons-organigram::before {
    content: "\ecb9";
}

.macicons-orrery::before {
    content: "\ecba";
}

.macicons-overdose::before {
    content: "\ecbb";
}

.macicons-overdrive::before {
    content: "\ecbc";
}

.macicons-overmind::before {
    content: "\ecbd";
}

.macicons-owl::before {
    content: "\ecbe";
}

.macicons-packed-planks::before {
    content: "\ecbf";
}

.macicons-padlock-open::before {
    content: "\ecc0";
}

.macicons-padlock::before {
    content: "\ecc1";
}

.macicons-pay-money::before {
    content: "\ecc2";
}

.macicons-pendant-key::before {
    content: "\ecc3";
}

.macicons-pentarrows-tornado::before {
    content: "\ecc4";
}

.macicons-person::before {
    content: "\ecc5";
}

.macicons-pierced-body::before {
    content: "\ecc6";
}

.macicons-pierced-heart::before {
    content: "\ecc7";
}

.macicons-piercing-sword::before {
    content: "\ecc8";
}

.macicons-pill-drop::before {
    content: "\ecc9";
}

.macicons-plain-dagger::before {
    content: "\ecca";
}

.macicons-plant-seed::before {
    content: "\eccb";
}

.macicons-plant-watering::before {
    content: "\eccc";
}

.macicons-plasma-bolt::before {
    content: "\eccd";
}

.macicons-player-base::before {
    content: "\ecce";
}

.macicons-player-time::before {
    content: "\eccf";
}

.macicons-pocket-bow::before {
    content: "\ecd0";
}

.macicons-pointing::before {
    content: "\ecd1";
}

.macicons-pointy-hat::before {
    content: "\ecd2";
}

.macicons-pointy-sword::before {
    content: "\ecd3";
}

.macicons-poison-bottle::before {
    content: "\ecd4";
}

.macicons-poison-gas::before {
    content: "\ecd5";
}

.macicons-polar-bear::before {
    content: "\ecd6";
}

.macicons-polar-star::before {
    content: "\ecd7";
}

.macicons-portal::before {
    content: "\ecd8";
}

.macicons-potion-ball::before {
    content: "\ecd9";
}

.macicons-pouring-chalice::before {
    content: "\ecda";
}

.macicons-pouring-pot::before {
    content: "\ecdb";
}

.macicons-powder::before {
    content: "\ecdc";
}

.macicons-power-lightning::before {
    content: "\ecdd";
}

.macicons-power-ring::before {
    content: "\ecde";
}

.macicons-prayer-beads::before {
    content: "\ecdf";
}

.macicons-prayer::before {
    content: "\ece0";
}

.macicons-pretty-fangs::before {
    content: "\ece1";
}

.macicons-prism::before {
    content: "\ece2";
}

.macicons-profit::before {
    content: "\ece3";
}

.macicons-psychic-waves::before {
    content: "\ece4";
}

.macicons-pulse::before {
    content: "\ece5";
}

.macicons-punch-blast::before {
    content: "\ece6";
}

.macicons-punch::before {
    content: "\ece7";
}

.macicons-pyromaniac::before {
    content: "\ece8";
}

.macicons-qaitbay-citadel::before {
    content: "\ece9";
}

.macicons-quake-stomp::before {
    content: "\ecea";
}

.macicons-quicksand::before {
    content: "\eceb";
}

.macicons-quiver::before {
    content: "\ecec";
}

.macicons-radiations::before {
    content: "\eced";
}

.macicons-rainbow-star::before {
    content: "\ecee";
}

.macicons-rat::before {
    content: "\ecef";
}

.macicons-rear-aura::before {
    content: "\ecf0";
}

.macicons-receive-money::before {
    content: "\ecf1";
}

.macicons-regeneration::before {
    content: "\ecf2";
}

.macicons-relic-blade::before {
    content: "\ecf3";
}

.macicons-remedy::before {
    content: "\ecf4";
}

.macicons-resonance::before {
    content: "\ecf5";
}

.macicons-reticule::before {
    content: "\ecf6";
}

.macicons-return-arrow::before {
    content: "\ecf7";
}

.macicons-revolt::before {
    content: "\ecf8";
}

.macicons-ribbon-shield::before {
    content: "\ecf9";
}

.macicons-ricochet::before {
    content: "\ecfa";
}

.macicons-ringed-beam::before {
    content: "\ecfb";
}

.macicons-riposte::before {
    content: "\ecfc";
}

.macicons-robe::before {
    content: "\ecfd";
}

.macicons-rock-2::before {
    content: "\ecfe";
}

.macicons-rolling-bomb::before {
    content: "\ecff";
}

.macicons-rolling-dices::before {
    content: "\ed00";
}

.macicons-rolling-energy::before {
    content: "\ed01";
}

.macicons-rope-dart::before {
    content: "\ed02";
}

.macicons-rosa-shield::before {
    content: "\ed03";
}

.macicons-rose::before {
    content: "\ed04";
}

.macicons-round-potion::before {
    content: "\ed05";
}

.macicons-round-shield::before {
    content: "\ed06";
}

.macicons-round-struck::before {
    content: "\ed07";
}

.macicons-round-table::before {
    content: "\ed08";
}

.macicons-rub-el-hizb::before {
    content: "\ed09";
}

.macicons-rune-stone::before {
    content: "\ed0a";
}

.macicons-rune-sword::before {
    content: "\ed0b";
}

.macicons-running-ninja::before {
    content: "\ed0c";
}

.macicons-rusty-sword::before {
    content: "\ed0d";
}

.macicons-saber-and-pistol::before {
    content: "\ed0e";
}

.macicons-saber-slash::before {
    content: "\ed0f";
}

.macicons-saber-tooth::before {
    content: "\ed10";
}

.macicons-sabers-choc::before {
    content: "\ed11";
}

.macicons-sacrificial-dagger::before {
    content: "\ed12";
}

.macicons-sai::before {
    content: "\ed13";
}

.macicons-salamander::before {
    content: "\ed14";
}

.macicons-sands-of-time::before {
    content: "\ed15";
}

.macicons-sandstorm::before {
    content: "\ed16";
}

.macicons-saphir::before {
    content: "\ed17";
}

.macicons-scabbard::before {
    content: "\ed18";
}

.macicons-screaming::before {
    content: "\ed19";
}

.macicons-scroll-quill::before {
    content: "\ed1a";
}

.macicons-scroll-unfurled::before {
    content: "\ed1b";
}

.macicons-sea-cliff::before {
    content: "\ed1c";
}

.macicons-secret-book::before {
    content: "\ed1d";
}

.macicons-secret-door::before {
    content: "\ed1e";
}

.macicons-self-love::before {
    content: "\ed1f";
}

.macicons-semi-closed-eye::before {
    content: "\ed20";
}

.macicons-shadow-follower::before {
    content: "\ed21";
}

.macicons-shambling-zombie::before {
    content: "\ed22";
}

.macicons-shamrock::before {
    content: "\ed23";
}

.macicons-shard-sword::before {
    content: "\ed24";
}

.macicons-sharp-axe::before {
    content: "\ed25";
}

.macicons-sharp-halberd::before {
    content: "\ed26";
}

.macicons-shatter::before {
    content: "\ed27";
}

.macicons-shattered-heart::before {
    content: "\ed28";
}

.macicons-shield-bash::before {
    content: "\ed29";
}

.macicons-shield-bounces::before {
    content: "\ed2a";
}

.macicons-shield-disabled::before {
    content: "\ed2b";
}

.macicons-shield-echoes::before {
    content: "\ed2c";
}

.macicons-shield-impact::before {
    content: "\ed2d";
}

.macicons-shield-opposition::before {
    content: "\ed2e";
}

.macicons-shield-reflect::before {
    content: "\ed2f";
}

.macicons-shield::before {
    content: "\ed30";
}

.macicons-shining-heart::before {
    content: "\ed31";
}

.macicons-shining-sword::before {
    content: "\ed32";
}

.macicons-shouting::before {
    content: "\ed33";
}

.macicons-sickle::before {
    content: "\ed34";
}

.macicons-silver-bullet::before {
    content: "\ed35";
}

.macicons-sing::before {
    content: "\ed36";
}

.macicons-sinusoidal-beam::before {
    content: "\ed37";
}

.macicons-skills::before {
    content: "\ed38";
}

.macicons-skull-bolt::before {
    content: "\ed39";
}

.macicons-skull-shield::before {
    content: "\ed3a";
}

.macicons-skull-staff::before {
    content: "\ed3b";
}

.macicons-slap::before {
    content: "\ed3c";
}

.macicons-slashed-shield::before {
    content: "\ed3d";
}

.macicons-slavery-whip::before {
    content: "\ed3e";
}

.macicons-slicing-arrow::before {
    content: "\ed3f";
}

.macicons-small-fire::before {
    content: "\ed40";
}

.macicons-smart::before {
    content: "\ed41";
}

.macicons-smoking-finger::before {
    content: "\ed42";
}

.macicons-smoking-orb::before {
    content: "\ed43";
}

.macicons-snake-jar::before {
    content: "\ed44";
}

.macicons-snow-bottle::before {
    content: "\ed45";
}

.macicons-snowflake-2::before {
    content: "\ed46";
}

.macicons-snowing::before {
    content: "\ed47";
}

.macicons-snowman::before {
    content: "\ed48";
}

.macicons-solar-time::before {
    content: "\ed49";
}

.macicons-sonic-boom::before {
    content: "\ed4a";
}

.macicons-sonic-lightning::before {
    content: "\ed4b";
}

.macicons-sonic-screech::before {
    content: "\ed4c";
}

.macicons-sonic-shout::before {
    content: "\ed4d";
}

.macicons-spark-spirit::before {
    content: "\ed4e";
}

.macicons-sparkles::before {
    content: "\ed4f";
}

.macicons-sparkling-sabre::before {
    content: "\ed50";
}

.macicons-sparky-bomb::before {
    content: "\ed51";
}

.macicons-spear-feather::before {
    content: "\ed52";
}

.macicons-spectre::before {
    content: "\ed53";
}

.macicons-spell-book::before {
    content: "\ed54";
}

.macicons-spider-alt::before {
    content: "\ed55";
}

.macicons-spider-web::before {
    content: "\ed56";
}

.macicons-spiked-dragon-head::before {
    content: "\ed57";
}

.macicons-spiked-shield::before {
    content: "\ed58";
}

.macicons-spiked-tail::before {
    content: "\ed59";
}

.macicons-spiked-wall::before {
    content: "\ed5a";
}

.macicons-spiky-wing::before {
    content: "\ed5b";
}

.macicons-spinning-blades::before {
    content: "\ed5c";
}

.macicons-spinning-ribbons::before {
    content: "\ed5d";
}

.macicons-spinning-sword::before {
    content: "\ed5e";
}

.macicons-spiral-arrow::before {
    content: "\ed5f";
}

.macicons-spiral-bottle::before {
    content: "\ed60";
}

.macicons-spiral-tentacle::before {
    content: "\ed61";
}

.macicons-spiral-thrust::before {
    content: "\ed62";
}

.macicons-sprint::before {
    content: "\ed63";
}

.macicons-spyglass::before {
    content: "\ed64";
}

.macicons-square-bottle::before {
    content: "\ed65";
}

.macicons-star-formation::before {
    content: "\ed66";
}

.macicons-star-key::before {
    content: "\ed67";
}

.macicons-star-sattelites::before {
    content: "\ed68";
}

.macicons-star-skull::before {
    content: "\ed69";
}

.macicons-star-swirl::before {
    content: "\ed6a";
}

.macicons-stars-stack::before {
    content: "\ed6b";
}

.macicons-staryu::before {
    content: "\ed6c";
}

.macicons-static-waves::before {
    content: "\ed6d";
}

.macicons-static::before {
    content: "\ed6e";
}

.macicons-steam-blast::before {
    content: "\ed6f";
}

.macicons-steam::before {
    content: "\ed70";
}

.macicons-stiletto-2::before {
    content: "\ed71";
}

.macicons-stiletto::before {
    content: "\ed72";
}

.macicons-stomp-tornado::before {
    content: "\ed73";
}

.macicons-stone-axe::before {
    content: "\ed74";
}

.macicons-stone-spear::before {
    content: "\ed75";
}

.macicons-striking-arrows::before {
    content: "\ed76";
}

.macicons-striking-balls::before {
    content: "\ed77";
}

.macicons-striking-clamps::before {
    content: "\ed78";
}

.macicons-striking-diamonds::before {
    content: "\ed79";
}

.macicons-striking-splinter::before {
    content: "\ed7a";
}

.macicons-striped-sword::before {
    content: "\ed7b";
}

.macicons-strong::before {
    content: "\ed7c";
}

.macicons-stump-regrowth::before {
    content: "\ed7d";
}

.macicons-sun-priest::before {
    content: "\ed7e";
}

.macicons-sun-radiations::before {
    content: "\ed7f";
}

.macicons-sunbeams::before {
    content: "\ed80";
}

.macicons-supersonic-arrow::before {
    content: "\ed81";
}

.macicons-supersonic-bullet::before {
    content: "\ed82";
}

.macicons-surprised::before {
    content: "\ed83";
}

.macicons-surrounded-shield::before {
    content: "\ed84";
}

.macicons-suspicious::before {
    content: "\ed85";
}

.macicons-swap-bag::before {
    content: "\ed86";
}

.macicons-swirl-ring::before {
    content: "\ed87";
}

.macicons-swirl-string::before {
    content: "\ed88";
}

.macicons-switch-weapon::before {
    content: "\ed89";
}

.macicons-sword-altar::before {
    content: "\ed8a";
}

.macicons-sword-array::before {
    content: "\ed8b";
}

.macicons-sword-brandish::before {
    content: "\ed8c";
}

.macicons-sword-break::before {
    content: "\ed8d";
}

.macicons-sword-clash::before {
    content: "\ed8e";
}

.macicons-sword-mold::before {
    content: "\ed8f";
}

.macicons-sword-smithing::before {
    content: "\ed90";
}

.macicons-sword-spin::before {
    content: "\ed91";
}

.macicons-sword-tie::before {
    content: "\ed92";
}

.macicons-sword-wound::before {
    content: "\ed93";
}

.macicons-swords-emblem::before {
    content: "\ed94";
}

.macicons-swords-power::before {
    content: "\ed95";
}

.macicons-take-my-money::before {
    content: "\ed96";
}

.macicons-target-arrows::before {
    content: "\ed97";
}

.macicons-target-dummy::before {
    content: "\ed98";
}

.macicons-target-poster::before {
    content: "\ed99";
}

.macicons-target-prize::before {
    content: "\ed9a";
}

.macicons-targeting::before {
    content: "\ed9b";
}

.macicons-tavern-sign::before {
    content: "\ed9c";
}

.macicons-telepathy::before {
    content: "\ed9d";
}

.macicons-teleport::before {
    content: "\ed9e";
}

.macicons-templar-eye::before {
    content: "\ed9f";
}

.macicons-temporary-shield::before {
    content: "\eda0";
}

.macicons-tension-snowflake::before {
    content: "\eda1";
}

.macicons-tentacle-heart::before {
    content: "\eda2";
}

.macicons-tesla-coil::before {
    content: "\eda3";
}

.macicons-third-eye::before {
    content: "\eda4";
}

.macicons-thor-fist::before {
    content: "\eda5";
}

.macicons-thorn-helix::before {
    content: "\eda6";
}

.macicons-thorned-arrow::before {
    content: "\eda7";
}

.macicons-three-friends::before {
    content: "\eda8";
}

.macicons-throwing-ball::before {
    content: "\eda9";
}

.macicons-thrown-charcoal::before {
    content: "\edaa";
}

.macicons-thrown-daggers::before {
    content: "\edab";
}

.macicons-thrown-knife::before {
    content: "\edac";
}

.macicons-thrown-spear::before {
    content: "\edad";
}

.macicons-thrust-bend::before {
    content: "\edae";
}

.macicons-thunder-blade::before {
    content: "\edaf";
}

.macicons-thunder-skull::before {
    content: "\edb0";
}

.macicons-thunder-struck::before {
    content: "\edb1";
}

.macicons-thunderball::before {
    content: "\edb2";
}

.macicons-tiara::before {
    content: "\edb3";
}

.macicons-tied-scroll::before {
    content: "\edb4";
}

.macicons-tiger-head::before {
    content: "\edb5";
}

.macicons-tightrope::before {
    content: "\edb6";
}

.macicons-time-trap::before {
    content: "\edb7";
}

.macicons-tired-eye::before {
    content: "\edb8";
}

.macicons-torch::before {
    content: "\edb9";
}

.macicons-tornado-discs::before {
    content: "\edba";
}

.macicons-tornado::before {
    content: "\edbb";
}

.macicons-trade::before {
    content: "\edbc";
}

.macicons-transfuse::before {
    content: "\edbd";
}

.macicons-transportation-rings::before {
    content: "\edbe";
}

.macicons-tree-branch::before {
    content: "\edbf";
}

.macicons-tribal-shield::before {
    content: "\edc0";
}

.macicons-trident-shield::before {
    content: "\edc1";
}

.macicons-truce::before {
    content: "\edc2";
}

.macicons-trumpet-flag::before {
    content: "\edc3";
}

.macicons-tumbleweed::before {
    content: "\edc4";
}

.macicons-turd::before {
    content: "\edc5";
}

.macicons-turtle-shell::before {
    content: "\edc6";
}

.macicons-twin-shell::before {
    content: "\edc7";
}

.macicons-twister::before {
    content: "\edc8";
}

.macicons-two-coins::before {
    content: "\edc9";
}

.macicons-two-shadows::before {
    content: "\edca";
}

.macicons-ultrasound::before {
    content: "\edcb";
}

.macicons-uncertainty::before {
    content: "\edcc";
}

.macicons-unfriendly-fire::before {
    content: "\edcd";
}

.macicons-unlocking::before {
    content: "\edce";
}

.macicons-unstable-orb::before {
    content: "\edcf";
}

.macicons-unstable-projectile::before {
    content: "\edd0";
}

.macicons-uprising::before {
    content: "\edd1";
}

.macicons-usable::before {
    content: "\edd2";
}

.macicons-vibrating-ball::before {
    content: "\edd3";
}

.macicons-vibrating-shield::before {
    content: "\edd4";
}

.macicons-vine-whip::before {
    content: "\edd5";
}

.macicons-vines::before {
    content: "\edd6";
}

.macicons-vortex::before {
    content: "\edd7";
}

.macicons-vuvuzelas::before {
    content: "\edd8";
}

.macicons-warhammer::before {
    content: "\edd9";
}

.macicons-warlock-eye::before {
    content: "\edda";
}

.macicons-warlord-helmet::before {
    content: "\eddb";
}

.macicons-water-bolt::before {
    content: "\eddc";
}

.macicons-water-recycling::before {
    content: "\eddd";
}

.macicons-water-splash::before {
    content: "\edde";
}

.macicons-waterfall::before {
    content: "\eddf";
}

.macicons-wave-crest::before {
    content: "\ede0";
}

.macicons-wave-strike::before {
    content: "\ede1";
}

.macicons-waves::before {
    content: "\ede2";
}

.macicons-web-spit::before {
    content: "\ede3";
}

.macicons-whirlwind::before {
    content: "\ede4";
}

.macicons-wildfires::before {
    content: "\ede5";
}

.macicons-willow-tree::before {
    content: "\ede6";
}

.macicons-wind-hole::before {
    content: "\ede7";
}

.macicons-wind-slap::before {
    content: "\ede8";
}

.macicons-wing-cloak::before {
    content: "\ede9";
}

.macicons-winged-arrow::before {
    content: "\edea";
}

.macicons-winged-scepter::before {
    content: "\edeb";
}

.macicons-winged-shield::before {
    content: "\edec";
}

.macicons-winged-sword::before {
    content: "\eded";
}

.macicons-wingfoot::before {
    content: "\edee";
}

.macicons-wisdom::before {
    content: "\edef";
}

.macicons-wolf-head::before {
    content: "\edf0";
}

.macicons-wood-beam::before {
    content: "\edf1";
}

.macicons-worried-eyes::before {
    content: "\edf2";
}

.macicons-yarn::before {
    content: "\edf3";
}

.macicons-yin-yang::before {
    content: "\edf4";
}

.macicons-zebra-shield::before {
    content: "\edf5";
}

.macicons-zeus-sword::before {
    content: "\edf6";
}
