body {
  $background-dark: rgba(50, 50, 50, 0.1);
  $background-light: rgba(200, 200, 200, 0.1);

  background-color: #8c3310;
  background-image: repeating-linear-gradient(
      120deg,
      $background-light,
      $background-light 1px,
      transparent 1px,
      transparent 60px
    ),
    repeating-linear-gradient(
      60deg,
      $background-light,
      $background-light 1px,
      transparent 1px,
      transparent 60px
    ),
    linear-gradient(
      60deg,
      $background-dark 15%,
      transparent 15%,
      transparent 85%,
      $background-dark 85%,
      $background-dark
    ),
    linear-gradient(
      120deg,
      $background-dark 15%,
      transparent 15%,
      transparent 85%,
      $background-dark 85%,
      $background-dark
    );
  background-size: 70px 120px;
  background-attachment: fixed;
  overflow: visible;
  height: 100vh;

  * {
    font-family: $font;
  }
}

body.fantasy {
  *:not(.mat-icon):not(.macicons) {
    font-family: $fantasy-font;
  }

  button {
    letter-spacing: 1px;
    font-weight: bold;
  }
}

img,
.mat-select-value-text,
.bar-text,
.background-text,
mat-chip,
mat-icon,
.loader,
.mat-form-field-label,
input::placeholder {
  user-select: none;
}

mat-dialog-actions {
  justify-content: space-between;
}

.mat-button-toggle.icon-button {
  min-width: 120px;

  .mat-button-toggle-label-content {
    line-height: 36px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// custom material overrides for in-game
.game {
  // these are free child elements
  .mat-form-field-infix {
    border-top: 0;
  }

  mat-form-field:not(.show-underline) .mat-form-field-underline {
    display: none;
  }

  // these are panels
  &.mat-select-panel .mat-option {
    height: 32px;
    line-height: 32px;
  }
}

.tooltip-card {
  width: 200px;
  padding: 8px;
  border: 1px solid #212121;

  mat-card-title {
    font-size: 20px;
  }
}

.mat-tooltip {
  font-size: 1rem;
}

.slider-option-row {
  display: flex;
  align-items: center;

  .slider-label {
    min-width: 100px;
  }
}

.mat-tab-header {
  margin-bottom: 20px;
}

.radio-row {
  display: flex;
  justify-content: space-between;

  .radio-label {
    flex: 1;
  }

  mat-radio-group {
    flex: 2;

    display: flex;
    justify-content: space-between;

    .mat-radio-button {
      min-width: 100px;
    }
  }
}

.mat-tab-body,
.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-snack-bar-container {
  background: #545454;
  color: #fff;

  padding: 0 !important;

  .mat-simple-snackbar {
    padding: 8px 24px;
  }

  &.error .mat-simple-snackbar {
    border: 25px solid #f00 !important;
    border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23ff0000' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23ff0000'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")
      25 !important;
  }
}

.cdk-overlay-pane.fancy .mat-dialog-container {
  padding-top: 0;
}

.cdk-overlay-pane.multi-column-options {
  .mat-dialog-actions {
    flex-direction: row;

    .mat-button {
      width: 45%;
    }
  }
}

.mat-dialog-content {
  overflow: hidden !important;
  max-height: 700px !important;

  @media only screen and (max-height: 900px) {
    max-height: 400px !important;
    overflow-y: scroll !important;
  }
}

// https://codepen.io/shanomurphy/pen/ejpVLw
.cdk-overlay-pane.fancy .mat-dialog-container,
.mat-snack-bar-container.fancy .mat-simple-snackbar,
.achievement-popup {
  border: 25px solid var(--border-color, #b88846);
  border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23B88846' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23B88846'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E")
    25;
}

ngx-mat-color-picker-content {
  background-color: #212121 !important;

  mat-label.symbol {
    color: #fff !important;
  }

  form .row:nth-child(2) mat-form-field:nth-child(3) {
    display: none;
  }
}

.mat-dialog-actions .mat-button-base {
  margin-left: 8px !important;
}

mat-horizontal-stepper.disable .mat-horizontal-stepper-header-container {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

md-tabs [role='tabpanel'] {
  transition: none;
}

.mat-ink-bar {
  transition: none !important;
}

mat-tab-group.bring-to-top .mat-tab-header {
  margin-bottom: 0 !important;
}

.mat-line {
  white-space: normal !important;
}

.text-danger {
  color: #ff6666 !important;
}

.text-success {
  color: #0a0 !important;
}

.mat-icon-button.change-icon-color {
  mat-icon {
    color: inherit;
  }
}
